import Client from 'config/axios';

export const getGroupAmbassador = async (
  token: string,
  page: number,
  limit: 500,
  text: string
) => {
  try {
    return await Client.get('search-for-ambassadors-and-groups?', {
      params: {
        text: text,
        page: page,
        limit: limit
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    return Promise.reject(error.response || error.message);
  }
};
