import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditorInput from 'components/inputs/EditorInput';
import Button from 'components/buttons/Button';
import { ContentEditorWrapper, TextPlain } from './Styled';
import ContentValidator from './ContentValidator';
import TwitterText from 'twitter-text';
import { usePostActions } from '../PostActions';
import { AcceptPostRequest } from 'services/rest/post';
import { useParams } from 'react-router-dom';
import { CampaignParams } from 'pages/CampaignDetailPage/CampaignDetailPage';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { ErrorSpan } from 'components/inputs/UploadFile/StyleUploadFile';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { ICampaigns } from 'redux/slices/Campaigns/Campaigns.interface';
import Tabs from 'components/Tabs';
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

export interface ContentEditorProps {
  provider: string;
  postId: number;
  label: string;
  maxCharacters?: number;
  originalContent: string;
  originalContent2?: string;
  originalContent3?: string;
  shouldValidateContent?: boolean;
  setShowButton?: any;
  getRankingData?: any;
  setPublicationLoader?: any;
  isTwitter: boolean;
  disabled: boolean;
  campaign?: ICampaigns;
  programmerPost: boolean;
  contents: any;
}

const ContentEditor: React.FC<ContentEditorProps> = props => {
  const [valueDate, setValueDate] = useState<Value>(new Date());
  const { campaignId } = useParams<CampaignParams>();
  const [contentTab, setContentTab] = useState(0);

  const {
    provider,
    postId,
    label,
    maxCharacters,
    originalContent,
    originalContent2,
    originalContent3,
    shouldValidateContent = true,
    setShowButton,
    getRankingData,
    setPublicationLoader,
    isTwitter,
    disabled,
    campaign,
    programmerPost,
    contents
  } = props;

  const { onAcceptPost, onErrorPost } = usePostActions();

  const { t } = useTranslation([
    'content_editor',
    'campaigns_page',
    'settings'
  ]);
  const [content, setContent] = useState<string>(originalContent);
  const [loader, setLoader] = useState(false);
  const [backendError, setBackendError] = useState<string | null>(null);
  const [additionalChanges, setAdditionalChanges] = useState('');

  useEffect(() => {
    const contentArray = [
      originalContent,
      originalContent2,
      originalContent3
    ].filter(Boolean);

    setContent(() => contentArray[contentTab] as string);
    setAdditionalChanges('');
  }, [contentTab, originalContent, originalContent2, originalContent3]);

  const { currentUser } = useSelector(selectUser);

  const contentLimits = {
    twitter: 280,
    linkedin: 1500,
    tiktok: 150
  };

  const dataMentions =
    provider === 'linkedin'
      ? campaign?.contentPerSocialNetwork[provider].mentions
      : '';

  const processContent = (content: any) => {
    return content.replace(
      /@\[[^\]]+\]\(urn:li:organization:[0-9]+\)/g,
      (dataCampaign: any) => {
        const displayName = dataCampaign.match(/@\[(.*?)\]/)[1];
        return `@[${displayName}]`;
      }
    );
  };

  const showPostMessageBack = () => {
    setShowButton('');
  };

  const no_characters = /^(?!.*[!@#$%^&*()\-_+={}[\]|\\;:'"ª|!· º\\,<.>/?]{5}).+/;

  const regextValidationTwitter = (content: string) => {
    const regex = new RegExp(no_characters);
    return regex.test(content);
  };

  const onPublish = async () => {
    let contentSNLimit = 0;
    if (provider === 'linkedin') {
      contentSNLimit = contentLimits.linkedin;
    } else {
      contentSNLimit = contentLimits.tiktok;
    }

    if (isTwitter) {
      const validationContent = regextValidationTwitter(content);
      const contentTwitterLength = content
        ? TwitterText.getTweetLength(content)
        : 0;
      if (!validationContent) {
        setBackendError(t('campaigns_page:characters'));
        return;
      } else if (contentTwitterLength > contentLimits.twitter) {
        setBackendError(t('campaigns_page:error_limit'));
        return;
      }
    } else {
      const contentLength = content ? content.length : 0;
      if (contentLength > contentSNLimit) {
        setBackendError(t('campaigns_page:error_limit'));
        return;
      }
    }

    let newContent = additionalChanges;
    if (additionalChanges && dataMentions) {
      for (const mention of dataMentions) {
        const regex = /@\[[^\]]+\]\(urn:li:organization:\d+\)/;
        const matches = regex.exec(mention);

        if (matches) {
          const originalMention = matches[0];

          const regexMencion = /@\[([^\]]+)\]/;

          const mention = regexMencion.exec(originalMention);

          if (mention) {
            const mentionClear = mention[1].replace(
              /[.*+?^${}()|[\]\\]/g,
              '\\$&'
            );
            const regexMention = new RegExp(`@\\[${mentionClear}\\]`, 'g');
            const contentReplace = newContent.replace(
              regexMention,
              originalMention
            );
            newContent = contentReplace;
          }
        }
      }
    }

    const refactorContent = additionalChanges ? newContent : content;

    setLoader(true);
    setPublicationLoader(true);
    const { data, errors } = await AcceptPostRequest(
      Number(campaignId),
      refactorContent,
      provider,
      currentUser?.token,
      '',
      programmerPost,
      valueDate
    );
    if (data) {
      onAcceptPost(postId, data.data);
      setLoader(false);
      setPublicationLoader(false);
      setShowButton(data.data);
      getRankingData();
    }
    if (errors) {
      onErrorPost(t('settings:error_admin'));
      if (errors && errors.data) {
        onErrorPost(errors.data);
      }
      setLoader(false);
      setPublicationLoader(false);
      setShowButton('pending');
    }
  };

  useEffect(() => {
    setContent(
      () =>
        contents[
          `content${contentTab > 0 ? `${contentTab + 1}` : ''}`
        ] as string
    );
    setAdditionalChanges('');
  }, [contentTab]);

  return (
    <ContentEditorWrapper>
      <TextPlain>
        <p>{label}</p>
      </TextPlain>
      {[originalContent, originalContent2, originalContent3].filter(Boolean)
        .length > 1 ? (
        <Tabs
          orientation="horizontal"
          tabsTitle={[originalContent, originalContent2, originalContent3]
            .filter(Boolean)
            .map((_, index) => ({
              id: index + 1,
              label: `${t('campaigns_page:option')} ${index + 1}`
            }))}
          tab={contentTab}
          setTab={tab => setContentTab(tab)}
          tabsContent={[originalContent, originalContent2, originalContent3]
            .filter(Boolean)
            .map((content, index) => ({
              id: index + 1,
              children: (
                <TextPlain>
                  <EditorInput
                    isTwitter={isTwitter}
                    className="content-editor"
                    placeholder=""
                    content={processContent(content)}
                    maxCharacters={maxCharacters}
                    onChange={setAdditionalChanges}
                    disabled={disabled}
                  />
                </TextPlain>
              )
            }))}
        />
      ) : (
        <TextPlain>
          <EditorInput
            isTwitter={isTwitter}
            className="content-editor"
            placeholder=""
            content={processContent(originalContent)}
            maxCharacters={maxCharacters}
            onChange={setAdditionalChanges}
            disabled={disabled}
          />
        </TextPlain>
      )}

      {campaign && programmerPost && (
        <DateTimePicker
          locale={'es'}
          className="Calendar-time"
          onChange={setValueDate}
          value={valueDate}
          minDate={new Date()}
          maxDate={new Date(campaign?.finishDate)}
          isClockOpen={false}
          disableClock={true}
        />
      )}

      {shouldValidateContent && (
        <TextPlain>
          <ContentValidator
            content={content}
            originalContent={originalContent}
          />
        </TextPlain>
      )}

      {backendError && <ErrorSpan>{backendError}</ErrorSpan>}
      {programmerPost ? (
        <TextPlain>
          <Button
            className="programmerButton"
            value={t('content_editor:schedule_publication')}
            variant="tertiary"
            onClick={onPublish}
            loader={loader}
          />
          <div className="rejectParticipation">
            <span onClick={showPostMessageBack}>
              {t('content_editor:reject_participation')}
            </span>
          </div>
        </TextPlain>
      ) : (
        <TextPlain>
          <Button
            value={t('content_editor:publish')}
            variant="tertiary"
            onClick={onPublish}
            loader={loader}
          />
          <div className="rejectParticipation">
            <span onClick={showPostMessageBack}>
              {t('content_editor:reject_participation')}
            </span>
          </div>
        </TextPlain>
      )}
    </ContentEditorWrapper>
  );
};

export default ContentEditor;
