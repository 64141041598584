import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import {
  PositionColumn,
  Column,
  UserNameHeader,
  Row,
  Table,
  Th,
  Status
} from './StyledGroupTable';

import SocialMediaIcon from 'components/icons/SocialMediaIcon';

import EmployeeAvatar from 'images/avatars/employee.png';
import iconTrash from 'images/icons/trash.png';
import Icon from 'components/icons/Icon';

import { Colors } from 'styles/Constants';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { deleteAmbassador } from 'services/rest/Ambassador/deleteAmbassador';
import { getGroupById } from 'services/rest/Group/getGroupById';
import { GroupCard } from 'pages/GroupAdminPage/GroupAdminCard/GroupAdminCard';
import StatusIcon from 'components/icons/StatusIcon';

interface GroupParams {
  groupId: string;
  origin_group: string;
}
interface Group {
  label: string;
  checked: boolean;
}

export interface Employee {
  social_network: any;
  id: number;
  name: string;
  lastName: string;
  email?: string;
  phone?: string;
  photo: string;
  language?: string;
  password?: string;
  role?: string;
  linkedFacebook?: boolean;
  linkedTwitter?: boolean;
  linkedLinkedin?: boolean;
  linkedInstagram?: boolean;
  linkedTiktok?: boolean;
  groups?: Array<Group>;
  registered?: boolean;
  allowBonus?: boolean;
  unsubscribe?: boolean;
  code?: string;
}
export interface EmployeeUpdate extends Omit<Employee, 'groups'> {
  groupsIds: number[];
  groups: {
    [key: string]: {
      name: string;
    };
  };
}
export interface EmployeeCreate extends Omit<Employee, 'id' | 'groups'> {
  id?: number;
  companyId: number;
  password: string;
  groupsIds: number[];
  groups: {
    [key: string]: {
      name: string;
    };
  };
}

export type GroupEmployees = Array<Employee>;

export interface EmployeesTableProps {
  employees: GroupEmployees;
  isAll: boolean;
}

const EmployeesTable: React.FC<EmployeesTableProps> = props => {
  const { employees, isAll } = props;
  const { groupId } = useParams<GroupParams>();
  const { t } = useTranslation(['group_page', 'bonus']);
  const history = useHistory();
  const { currentUser } = useSelector(selectUser);
  const [group, setGroup] = useState<GroupCard>();

  const getData = async () => {
    const { data } = await getGroupById(currentUser?.token, groupId, 'false');
    setGroup(data.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const go = (id: number) => {
    history.replace(`/admin/group/editEmployee/${groupId}/${id}`);
  };

  const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
    evt.currentTarget.src = EmployeeAvatar;
  };

  const onSubmit = async (
    token: any,
    ambassadorId: any,
    removePermanent: any
  ) => {
    const { data } = await deleteAmbassador(
      token,
      ambassadorId,
      groupId,
      removePermanent
    );
    if (data.data) {
      window.location.reload();
    }
  };

  const confirmAlert = (ambassadorId: any) => {
    Swal.fire({
      text: t('group_page:confirmation_message_ambassador'),
      icon: 'warning',
      input: 'checkbox',
      inputValue: isAll,
      inputPlaceholder: t('group_page:checkbox_alert_ambassador'),
      customClass: {
        input: isAll ? 'hide' : 'show'
      },
      showCancelButton: true,
      confirmButtonText: t('group_page:yes'),
      cancelButtonText: t('group_page:not'),
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        onSubmit(currentUser?.token, ambassadorId, Boolean(result.value));
      }
    });
  };

  const renderSocialIcon = (
    linked?: boolean,
    invalidToken?: string,
    profileLink?: string
  ) => {
    if (linked) {
      if (invalidToken === 'true') {
        return <Icon name="alert-rounded" color="orange" size="small" />;
      } else {
        return (
          <a
            href={profileLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ cursor: 'pointer' }}
          >
            <Icon name="check-rounded" color="#7ed321" size="small" />
          </a>
        );
      }
    } else {
      return <Icon name="close-rounded" color="red" size="small" />;
    }
  };

  const generateProfileLink = (network: string, userName?: any) => {
    switch (network) {
      case 'facebook':
        return userName;
      case 'instagram':
        return `https://www.instagram.com/${userName}`;
      case 'linkedin':
        return `https://www.linkedin.com/in/${userName}`;
      case 'tiktok':
        return `https://www.tiktok.com/@${userName}`;
      case 'twitter':
        return `https://www.x.com/${userName}`;
      default:
        return null;
    }
  };

  return (
    <Table className="notranslate">
      <thead>
        <Row>
          <Th />
          <UserNameHeader>{t('group_page:name')}</UserNameHeader>
          <UserNameHeader>{t('group_page:last_name')}</UserNameHeader>
          <UserNameHeader className="textColumn">
            {t('group_page:email')}
          </UserNameHeader>
          <UserNameHeader className="text-center">
            {t('bonus:bonus_title')}
          </UserNameHeader>
          <UserNameHeader className="text-center">
            <SocialMediaIcon name={'facebook'} size="medium" />
          </UserNameHeader>
          <UserNameHeader className="text-center">
            <SocialMediaIcon name={'twitter'} size="medium" />
          </UserNameHeader>
          <UserNameHeader className="text-center">
            <SocialMediaIcon name={'linkedin'} size="medium" />
          </UserNameHeader>
          <UserNameHeader className="text-center">
            <SocialMediaIcon name={'instagram'} size="medium" />
          </UserNameHeader>
          <UserNameHeader className="text-center">
            <SocialMediaIcon name={'tiktok'} size="medium" />
          </UserNameHeader>
          <UserNameHeader />
          <UserNameHeader />
          <Th />
        </Row>
      </thead>
      <tbody>
        {employees.map((ambassador, index) => {
          return (
            <Row
              key={index}
              className={ambassador?.unsubscribe == true ? 'unsubscribe' : ''}
            >
              <PositionColumn collapsed>
                <div className="id">
                  <img
                    src={ambassador.photo || EmployeeAvatar}
                    className="user-photo"
                    alt="User"
                    onError={evt => addDefaultPicture(evt)}
                  />
                </div>
                {ambassador.registered === true && (
                  <Status>
                    <StatusIcon variant={'verified'} />
                  </Status>
                )}
              </PositionColumn>

              <Column>{ambassador.name}</Column>
              <Column>{ambassador.lastName}</Column>
              <Column>
                <p>{ambassador.email}</p>
              </Column>
              <Column className="text-center">
                {ambassador.allowBonus ? (
                  <Icon name="check-rounded" color="#7ed321" size="small" />
                ) : (
                  <Icon name="close-rounded" color="red" size="small" />
                )}
              </Column>
              <Column className="text-center">
                {renderSocialIcon(
                  ambassador.linkedFacebook,
                  ambassador.social_network?.fbInvalidToken,
                  generateProfileLink(
                    'facebook',
                    ambassador.social_network?.fbUserName
                  )
                )}
              </Column>
              <Column className="text-center">
                {renderSocialIcon(
                  ambassador.linkedTwitter,
                  ambassador.social_network?.xInvalidToken,
                  generateProfileLink(
                    'twitter',
                    ambassador.social_network?.xUserName
                  )
                )}
              </Column>
              <Column className="text-center">
                {renderSocialIcon(
                  ambassador.linkedLinkedin,
                  ambassador.social_network?.lnInvalidToken,
                  generateProfileLink(
                    'linkedin',
                    ambassador.social_network?.lnUserName
                  )
                )}
              </Column>
              <Column className="text-center">
                {renderSocialIcon(
                  ambassador.linkedInstagram,
                  ambassador.social_network?.inInvalidToken,
                  generateProfileLink(
                    'instagram',
                    ambassador.social_network?.inUserName
                  )
                )}
              </Column>
              <Column className="text-center">
                {renderSocialIcon(
                  ambassador?.linkedTiktok,
                  ambassador.social_network?.tkInvalidToken,
                  generateProfileLink(
                    'tiktok',
                    ambassador.social_network?.tkUserName
                  )
                )}
              </Column>
              <Column className="text-center">
                <Icon
                  name="edit"
                  color={Colors.primaryDarkBlue}
                  size="medium"
                  onClick={() => go(ambassador.id)}
                />
              </Column>
              <Column className="text-center">
                <img
                  alt="iconTrash"
                  src={iconTrash}
                  onClick={() => confirmAlert(ambassador.id)}
                />
              </Column>
            </Row>
          );
        })}
      </tbody>
    </Table>
  );
};

export default EmployeesTable;
