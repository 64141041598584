import React, { Suspense, useEffect, useState } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { getCompanyMetrics } from 'services/rest/CompanyMetrics/CompanyMetrics';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import {
  selectCompanyMetrics,
  setLoading,
  setMetrics
} from 'redux/slices/CompanyMetrics/CompanyMetricsSlice';
import SingleDataBox from 'components/boxes/SingleDataBox';
import MetricBox from 'components/boxes/MetricBox';
import NetworkParticipationBox from 'components/boxes/NetworkParticipationBox';
import { GridWrapper, ToggleMetricsWrapper } from './Styled';
import {
  StyledTooltipWrapper,
  TooltipMessage
} from 'components/TooltipWrapper/StyledTooltipWrapper';
import MetricImgBox from 'components/boxes/MetricBoxImg';
import iconApproved from 'images/icons/bonus_approved.svg';
import iconMoney from 'images/icons/money.svg';
import iconHeart from 'images/icons/heart.svg';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import { nFormatter } from 'helpers/formatHelper';
import { Button } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const MetricsBonusSectionAdmin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['bonus']);
  const { currentUser } = useSelector(selectUser);
  const { token } = currentUser;
  const { loading, companyMetrics } = useSelector(selectCompanyMetrics);
  const { company } = useSelector(selectCompany);
  const [expandMetrics, setExpandMetrics] = useState(false);
  const [visible, setVisible] = useState({
    totalValorization: false,
    totalPotentialReach: false,
    totalEstimatedReach: false
  });

  const handleClickToggle = () => setExpandMetrics(prev => !prev);

  const getData = async () => {
    const data = await getCompanyMetrics({
      token,
      typeFilter: 'bonus'
    });
    dispatch(setMetrics(data));
    dispatch(setLoading(false));
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getData();
  }, []);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const changeTooltip = (tooltipType: string, state: boolean) => {
    const tooltipTypes = {
      ...visible,
      [tooltipType]: state
    };
    setVisible(tooltipTypes);
  };

  const networksBoxItems = [
    {
      linked: true,
      network: 'facebook',
      percentage: companyMetrics?.percentageBonus?.facebook
    },
    {
      linked: true,
      network: 'twitter',
      percentage: companyMetrics?.percentageBonus?.twitter
    },
    {
      linked: true,
      network: 'linkedin',
      percentage: companyMetrics?.percentageBonus?.linkedin
    },
    {
      linked: true,
      network: 'instagram',
      percentage: companyMetrics?.percentageBonus?.instagram
    },
    {
      linked: true,
      network: 'tiktok',
      percentage: companyMetrics?.percentageBonus?.tiktok
    },
    {
      linked: true,
      network: 'snapchat',
      percentage: companyMetrics?.percentageBonus?.snapchat
    },
    {
      linked: true,
      network: 'youtube',
      percentage: companyMetrics?.percentageBonus?.youtube
    },
    {
      linked: true,
      network: 'threads',
      percentage: companyMetrics?.percentageBonus?.threads
    },
    {
      linked: true,
      network: 'otros',
      percentage: companyMetrics?.percentageBonus?.otros
    }
  ];

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <GridWrapper>
        <StyledTooltipWrapper visible={false}>
          <SingleDataBox>
            <MetricBox
              iconName={'report'}
              value={`${companyMetrics?.totalBonusApproved} / ${companyMetrics?.totalBonus}`}
              text={t('bonus:approved_total_contents')}
            />
          </SingleDataBox>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper visible={false}>
          <SingleDataBox>
            <MetricBox
              iconName="pending"
              value={companyMetrics?.totalBonustPending}
              text={t('bonus:plural_pending')}
            />
          </SingleDataBox>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper visible={false}>
          <SingleDataBox>
            <MetricImgBox
              iconName={iconApproved}
              value={companyMetrics?.totalAmbassadorsParticipating}
              text={t('bonus:ambassadors_participating')}
            />
          </SingleDataBox>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper
          visible={visible?.totalValorization}
          onMouseOver={() => changeTooltip('totalValorization', true)}
          onMouseOut={() => changeTooltip('totalValorization', false)}
        >
          <SingleDataBox>
            <MetricImgBox
              iconName={iconMoney}
              value={`${company?.currency?.sign}${nFormatter(
                companyMetrics?.totalValorization
              )}`}
              money={company?.currency?.code}
              text={t('bonus:total_valorization')}
            />
          </SingleDataBox>
          <TooltipMessage>
            {t('bonus:total_valorization_tooltip')}
          </TooltipMessage>
        </StyledTooltipWrapper>

        {expandMetrics && (
          <>
            <StyledTooltipWrapper
              visible={visible?.totalPotentialReach}
              onMouseOver={() => changeTooltip('totalPotentialReach', true)}
              onMouseOut={() => changeTooltip('totalPotentialReach', false)}
            >
              <SingleDataBox>
                <MetricBox
                  iconName="total-reach"
                  value={`${nFormatter(companyMetrics?.totalPotentialReach)}`}
                  text={t('bonus:potential_reach')}
                />
              </SingleDataBox>
              <TooltipMessage>
                {t('bonus:potential_reach_tooltip')}
              </TooltipMessage>
            </StyledTooltipWrapper>

            <StyledTooltipWrapper
              visible={visible?.totalEstimatedReach}
              onMouseOver={() => changeTooltip('totalEstimatedReach', true)}
              onMouseOut={() => changeTooltip('totalEstimatedReach', false)}
            >
              <SingleDataBox>
                <MetricImgBox
                  iconName={iconHeart}
                  value={`${nFormatter(companyMetrics?.totalEstimatedReach)}`}
                  text={t('bonus:estimated_reach')}
                />
              </SingleDataBox>
              <TooltipMessage>
                {t('bonus:estimated_reach_tooltip')}
              </TooltipMessage>
            </StyledTooltipWrapper>

            <StyledTooltipWrapper visible={false}>
              <SingleDataBox>
                <NetworkParticipationBox
                  onConnect={() => alert('Maybe some other time')}
                  items={networksBoxItems}
                  msgTopText={t('bonus:bonus_for_social_network')}
                  columns={2}
                />
              </SingleDataBox>
            </StyledTooltipWrapper>
          </>
        )}
      </GridWrapper>

      <ToggleMetricsWrapper>
        <Button
          onClick={handleClickToggle}
          endIcon={expandMetrics ? <ExpandLess /> : <ExpandMore />}
        >
          {expandMetrics ? t('bonus:see_less') : t('bonus:see_more')}
        </Button>
      </ToggleMetricsWrapper>
    </Suspense>
  );
};

export default MetricsBonusSectionAdmin;
