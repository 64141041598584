import styled from 'styled-components';

import { Colors, Breakpoints } from 'styles/Constants';
import BaseBox from 'components/boxes/BaseBox';
import { StyledNetworkParticipationBox } from 'components/boxes/NetworkParticipationBox';

export const GridWrapper = styled.div`
  @media only screen and (max-width: 900px) {
    width: 120%;
  }

  ${BaseBox};

  @media (min-width: ${Breakpoints.medium}) {
    height: 150px;
  }

  .content-img a {
    width: 150px;
    height: 150px;
    min-height: inherit !important;
    display: flex !important;
    align-items: center !important;
  }

  img {
    width: 100%;
    height: inherit;
    object-fit: contain;
  }

  display: grid;
  grid-template-columns: 200px 4fr 6fr 2fr;

  .time-line {
    margin: 0 0 5px 0;
    font-weight: 500;
    line-height: 1.5;
    font-size: 14px;
    color: ${Colors.primaryNormalPurple};
    text-transform: capitalize;
  }

  .campaign-stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
  .campaign-desc {
    position: relative;
  }
  .campaign-desc h3 {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
`;

export const MobileWrapper = styled.div`
  ${BaseBox};

  ${StyledNetworkParticipationBox} {
    padding-bottom: 15px;

    .button-collection {
      justify-content: flex-start;
    }

    h3 {
      text-align: center;
      width: 100%;
    }
  }
`;

export const MarginDiv = styled.div`
  margin-bottom: 20px;
  font-size: 0.6875rem;
  text-align: center;

  @media only screen and (max-width: 800px) {
    width: 80px;
  }

  p {
    text-align: center;
    color: #004fea;
    font-size: 30px;
    font-weight: 700;
    line-height: 1;
    margin: 0 0 5px 0;
  }

  h3 {
    width: 200px;
  }

  &.division-right {
    position: relative;
  }

  &.division-right:before {
    content: '';
    background-color: #d8d8d8;
    position: absolute;
    border-radius: 8px;
    height: 100%;
    width: 4px;
    right: 0px;
  }

  @media (min-width: ${Breakpoints.medium}) {
    p {
      font-size: 3.125rem;
    }
  }
`;

export const MarginStatus = styled.div`
  margin-bottom: 20px;
  font-size: 1.6rem;
  text-align: center;

  @media only screen and (max-width: 800px) {
    width: 80px;
  }

  p {
    text-align: center;
    color: #3d74e1;
    font-size: 30px;
    font-weight: 700;
    line-height: 1;
    margin: 5% 0 5px 0;
  }

  h3 {
    width: 200px;
  }

  &.division-right {
    position: relative;
  }

  &.division-right:before {
    content: '';
    background-color: #d8d8d8;
    position: absolute;
    border-radius: 8px;
    height: 100%;
    width: 4px;
    right: 0px;
  }

  @media (min-width: ${Breakpoints.medium}) {
    p {
      font-size: 1.6rem;
    }
  }
`;

export const Colum = styled.div`
  background-color: #d8d8d8;
  border-radius: 8px;
  margin-top: 50px;
  height: 50%;
  width: 4px;

  @media only screen and (max-width: 800px) {
    background-color: #ffff;
  }
`;

export const MarginRight = styled.div`
  margin-bottom: 20px;
  font-size: 0.6875rem;
  width: 150px;

  p {
    text-align: center;
    color: #004fea;
    font-size: 30px;
    font-weight: 700;
    line-height: 1;
  }

  h3 {
    width: 200px;
  }
`;

export const MarginText = styled.div`
  padding: 5px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  &.m-auto{
    margin: auto 0px;
  }
  a { 
    text-decoration: none;
  }
  div {
    h2 {      
      font-weight: bold;
      position: relative;
      font-size: 18px;
      margin: 0;
      color: #000;
    }
  }
  .overflow-elipsis{
    height: 100%;
    h2{
      height: 50px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
}
`;

export const MarginMobileText = styled.div`
  padding: 5px;
  font-weight: 500;
  font-size: 14px;

  div {
    h2 {
      font-weight: bold;
      position: relative;
      font-size: 18px;
      @media (max-width: ${Breakpoints.medium}) {
        text-align:center;
      }
    }
  }
}
`;

export const MarginTextMobile = styled.div`
  align-content: center;
  text-align: center;
  p {
    text-align: center;
    color: #004fea;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1;
  }
`;

export const ImgMobile = styled.div`
  img {
    margin-left: 20%;
  }
`;

export const DivIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  img {
    width: 25px;
    margin: 5px;
  }
`;

export const DivIconMobile = styled.div`
  columns: 3;
  margin-botton: 10%;

  img {
    width: 25px;
    margin-top: 10%;
    margin-left: 30%;
    padding: 5px 2px;
    float: left;
  }
`;

export const MarginAdminPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-size: 20px;
    color: #4d4d4d;
    font-weight: bold;
  }

  p {
    font-size: 28px;
    color: ${Colors.primaryGray};
    font-weight: bold;
  }

  button {
    font-size: 1.125rem;
    height: 20%;
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid #d113fe;
    background-color: #d113fe;
  }
`;

export const ColorButton = styled.div`
  button {
    font-size: 1.125rem;
    height: 20%;
    border: 1px solid #d113fe;
    background-color: #d113fe;
    margin-bottom: 30px;
  }
`;

export const FilterCampaigns = styled.div`
  display: flex;

  button {
    border-radius: 20px;
    font-size: 0.6875rem;
    padding: 6px 15px;
    height: 35px;
    margin-right: 20px;
  }

  input {
    width: 30%;
    margin-right: 20px;
  }

  span {
    margin-top: 7px;
  }
`;

export const FilterCampaignsMobile = styled.div`
  display: grid;

  button {
    border-radius: 20px;
    font-size: 0.6875rem;
    padding: 6px 15px;
    height: 35px;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
  }

  span {
    margin-top: 20px;
  }
`;

export const StyleTable = styled.div`
  .column {
    background-color: ${Colors.normalWhite};
    position: relative;
    font-weight: 500;
    font-size: 14px;
    color: ${Colors.secondaryNormalGrey};
    padding: 10px 15px;
    border: none;
    font-weight: bold;
  }
  .table {
    border-collapse: separate;
    border-spacing: 0 20px;
    font-size: 1rem;
    color: ${Colors.secondaryNormalGrey};
    margin-bottom: 10px;
    max-width: 100%;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%),
      0px 0px 0px 0px rgb(0 0 0 / 0%);
  }

  .table_container {
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%),
      0px 0px 0px 0px rgb(0 0 0 / 0%);
  }

  .column-body {
    box-shadow: 0 1px 6px 0 rgb(79 79 79 / 38%);
    transition: all 0.5s ease-out 0s;
    background: ${Colors.normalWhite};
    margin-bottom: 20px;
    border-radius: 10px;
    z-index: 2;
    height: 70px;

    th {
      font-family: 'Montserrat', sans-serif;
      font-size: 1.125rem;
      padding: 16px;
      border-collapse: separate;
      border-spacing: 0 20px;
      font-size: 1rem;
      color: ${Colors.secondaryNormalGrey};
      border-bottom: none;
    }

    th.actions * {
      cursor: pointer;
      margin: 0 5px;
      text-decoration: none;
      color: #000;
    }

    .content-networks {
      display: flex;
      flex-direction: row;
    }
    div {
      h2 {
        font-weight: bold;
        position: relative;
        font-size: 18px;
        margin: 0;
      }
    }
  }
`;

export const FilterSection = styled.div`
  display: flex;

  button {
    border-radius: 20px;
    font-size: 0.6875rem;
    padding: 6px 15px;
    height: 35px;
    margin-right: 20px;
  }

  input {
    width: 30%;
    margin-right: 20px;
  }

  span {
    margin-top: 7px;
  }
`;
export const SocialNetworkTags = styled.div`
  position: absolute;
  bottom: 0px;
  width: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MetricsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-top: 20px;

  @media (min-width: ${Breakpoints.medium}) {
    grid-column-gap: 20px;
  }

  > div:nth-of-type(-n + 4) {
    grid-column: span 3;
    margin-bottom: 20px;
  }

  .single-data-box {
    display: grid;
    margin-bottom: 0px;
    height: 100%;
  }
  .currency {
    color: #004fea;
    margin-top: -25px;
    font-weight: 700;
  }
`;

export const ToggleMetricsWrapper = styled.div`
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-end;

  button {
    color: ${Colors.primaryDarkBlue};
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.42857;
  }
`;
