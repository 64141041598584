import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import GroupDetailBody from './GroupDetailBody/GroupDetailBody';
import GroupDetailHead from './GroupDetailHead/GroupDetailHead';
import Icon from 'components/icons/Icon';
import { useLocation } from 'react-router-dom';
import { ErrorAlert, SuccessAlert } from 'components/Alert';

const GroupDetailPage = () => {
  const { t } = useTranslation(['group_page']);
  const location = useLocation();
  const { state } = location as any;
  const [bonusState, setBonusState] = useState<boolean | null>();
  const [backendSuccess, setBackendSuccess] = useState<any>(
    state === 'success' ? t('group_page:create_ambassador_success') : false
  );
  const [backendError, setBackendError] = useState<string | null>(null);
  return (
    <>
      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}
      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}
      <Icon name="check" size="small" />
      <SectionWrapper>
        <GroupDetailHead
          setBackendError={setBackendError}
          setBonusState={setBonusState}
        />
      </SectionWrapper>
      <SectionWrapper>
        <GroupDetailBody bonusState={bonusState} />
      </SectionWrapper>
    </>
  );
};

export default GroupDetailPage;
