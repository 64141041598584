import Client from 'config/axios';

export const getAmbassadorById = async (token: string, id: string) => {
  try {
    const { data } = await Client.get(`ambassador/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data };
  } catch (error) {
    return Promise.reject(error.response || error.message);
  }
};
