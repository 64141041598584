import React, { useEffect, useState, Suspense, SyntheticEvent } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import MetricBox from 'components/boxes/MetricBox';
import MetricImgBox from 'components/boxes/MetricBoxImg';
import SingleDataBox from 'components/boxes/SingleDataBox';
import NetworkParticipationBox from 'components/boxes/NetworkParticipationBox';
import NavigationLink from 'components/links/NavigationLink';
import ResumenTable from 'components/ResumenTable';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import Icon from 'components/icons/Icon';
import iconPerson from 'images/icons/person.svg';
import iconMoney from 'images/icons/money.svg';
import iconHeard from 'images/icons/heart.svg';
import iconEyes from 'images/icons/eyes.svg';
import eyeStrikethroughRed from 'images/icons/eyes_strikethrough.red.svg';
import programmingPost from 'images/icons/clock-programming.svg';
import iconNoAction from 'images/icons/no_actions.svg';
import iconStar from 'images/icons/star.svg';
import EmployeeAvatar from 'images/avatars/employee.png';
import {
  HeardMargin,
  BodyWrapper,
  GridWrapper,
  TextStyled,
  ResumenScrollContainer,
  InputStyled,
  ButtonStyled
} from './StyleCampaignsAdminDetail';
import Input from 'components/inputs/Input';
import Button from 'components/buttons/Button';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { getCampaignById } from 'services/rest/Campaign/getCampaignById';
import { Employees } from 'components/ResumenTable/ResumenTable';
import { getReport } from 'services/rest/Report/getReport';
import { createManualReport } from 'services/rest/Report/createManualReport';
import TableFooter from 'components/TableFooter';
import { nFormatter } from 'helpers/formatHelper';
import {
  StyledTooltipWrapper,
  TooltipMessage
} from 'components/TooltipWrapper/StyledTooltipWrapper';

import { ErrorAlert, SuccessAlert } from 'components/Alert';
import Swal from 'sweetalert2';
import { discardBadPracticesParticipation } from 'services/rest/post';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import { getCurrentCompany } from 'helpers/companyHelper';

interface CampaignParams {
  campaignId: string;
}

export interface CampaignDetails {
  id: number;
  name: string;
  photo: string;
  description: string;
  startDate: string;
  finishDate: string;
  groups: any;
  ambassadorsParticipating: number;
  ambassadorsWithConnectedNetworks: number;
  totalAmbassadors: number;
  totalParticipation: number;
  totalValorization: number;
  potentialReach: number;
  estimatedReach: number;
  engagements: number;
  cpe: number;
  allParticipants: any;
  percentageContentBySocialNetwork: any;
  includesFacebook: boolean;
  includesTwitter: boolean;
  includesLinkedin: boolean;
  includesInstagram: boolean;
  includesWhatsapp: boolean;
  includesTiktok: boolean;
  totalViews: any;
  status: string;
  notifiedReminded: boolean;
}

const CampaignsAdminDetail = () => {
  const { campaignId } = useParams<CampaignParams>();
  const { currentUser } = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState<CampaignDetails>();
  const [allParticipants, setAllParticipants] = useState<Employees>([]);
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [
    successReminderAlertVisible,
    setSuccessReminderAlertVisible
  ] = useState(false);
  const { t } = useTranslation([
    'campaign_card',
    'admin_campaign_details',
    'campaigns_page',
    'report_page'
  ]);
  const [name, setName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const { company } = useSelector(selectCompany);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const [networksBoxItems, setNetworksBoxItems] = useState([
    { linked: true, network: 'facebook', percentage: '0' },
    { linked: true, network: 'twitter', percentage: '0' },
    { linked: true, network: 'linkedin', percentage: '0' },
    { linked: true, network: 'instagram', percentage: '0' },
    { linked: true, network: 'whatsapp', percentage: '0' },
    { linked: true, network: 'tiktok', percentage: '0' }
  ]);
  const [visible, setVisible] = useState({
    totalValorization: false,
    followersAccountsParticipating: false,
    estimatedFollowers: false,
    engagements: false,
    visualizations: false,
    cpe: false
  });
  const subdomain = getCurrentCompany();

  const getData = async () => {
    try {
      const { data } = await getCampaignById(
        currentUser?.token,
        campaignId,
        'true'
      );

      validatePercentageContentBySocialNetwork(data.data);
      setCampaign(data.data);
      setAllParticipants(data.data.allParticipants);
      setLoading(false);
    } catch (error) {
      window.location.href = '/admin/campaigns';
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const validatePercentageContentBySocialNetwork = (campaign: any) => {
    const result: any[] = Object.keys(
      campaign.percentageContentBySocialNetwork
    ).map((key: string) => {
      return {
        linked:
          campaign[`includes${key.charAt(0).toUpperCase() + key.slice(1)}`],
        network: key,
        percentage: campaign?.percentageContentBySocialNetwork[key]?.toString()
      };
    });
    setNetworksBoxItems(result);
  };

  const downloadReport = async (typeReport: string) => {
    const { data } = await getReport(
      currentUser?.token,
      campaignId,
      typeReport,
      null
    );
    if (data.data) {
      setSuccessAlertVisible(true);
    } else {
      setUpdateError(t('report_page:error_page'));
    }
  };

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const new_ambassadors = allParticipants.filter((data: any) => {
    const userData = data as any;
    const userName = userData.name.toLowerCase();
    const usenameRemove = userName
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const userLastName = userData.lastName.toLowerCase();
    const usenLastNameRemove = userLastName
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const nameComplete = userName + ' ' + userLastName;
    const nameRemoveComplete = usenameRemove + ' ' + usenLastNameRemove;
    const result =
      nameComplete.indexOf(name.toLowerCase()) != -1 ||
      nameRemoveComplete.indexOf(name.toLowerCase()) != -1 ||
      userName.indexOf(name.toLowerCase()) != -1 ||
      usenameRemove.indexOf(name.toLowerCase()) != -1 ||
      userLastName.indexOf(name.toLowerCase()) != -1 ||
      usenLastNameRemove.indexOf(name.toLowerCase()) != -1;
    return result;
  });

  const currentRanking = name
    ? new_ambassadors
    : new_ambassadors?.slice(indexOfFirstPost, indexOfLastPost);

  const changeTooltip = (tooltipType: string, state: boolean) => {
    const tooltipTypes = {
      ...visible,
      [tooltipType]: state
    };
    setVisible(tooltipTypes);
  };

  const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
    evt.currentTarget.src = EmployeeAvatar;
  };

  const submit = async (participationId: number) => {
    await discardBadPracticesParticipation(
      currentUser?.token,
      participationId,
      'facebook'
    );
    window.location.reload();
  };

  const confirmAlert = (participationId: number) => {
    Swal.fire({
      text: t('admin_campaign_details:alert_bad_practices:message'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('admin_campaign_details:alert_bad_practices:yes'),
      cancelButtonText: t('admin_campaign_details:alert_bad_practices:not'),
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        submit(participationId);
      }
    });
  };

  const pathName = new URLSearchParams(window.location.search);
  let newPath = '';

  if (pathName.has('All')) {
    newPath = '/campaigns/admin/campaignsAll';
  }

  const manualReport = async (typeReport: string) => {
    const { data } = await createManualReport(
      currentUser?.token,
      campaignId,
      typeReport
    );
    if (data.data) {
      setSuccessReminderAlertVisible(true);
    } else {
      setUpdateError(t('report_page:error_page'));
    }
  };

  return (
    <>
      {updateError && (
        <ErrorAlert
          text={updateError}
          allowClose={true}
          onClose={() => setUpdateError(null)}
        />
      )}
      {successAlertVisible && (
        <SuccessAlert
          text={t('report_page:success_email')}
          allowClose={true}
          onClose={() => setSuccessAlertVisible(false)}
        />
      )}
      {successReminderAlertVisible && (
        <SuccessAlert
          text={t('report_page:reminder_campaign_success')}
          allowClose={true}
          onClose={() => setSuccessReminderAlertVisible(false)}
        />
      )}
      <Suspense fallback={<LoadingState height="230px" width="100%" />}>
        <SectionWrapper>
          <NavigationLink
            text={t('campaigns_page:back')}
            direction="back"
            to={newPath || '/admin/campaigns'}
          />
          <HeardMargin>
            <img
              alt="campaignPhoto"
              src={campaign?.photo}
              className="margin_img"
            />
            <div className="margin_container">
              <span>
                <Icon size="small" name="calendar-o" color="#bd10e0" />
                {moment(campaign?.startDate).format('MMM D')} {' - '}
                {moment(campaign?.finishDate).format('MMM D')}
              </span>
              <h1>{campaign?.name}</h1>
              <p className="description">{campaign?.description}</p>
              {Object.keys(campaign?.groups).length > 0 && (
                <div>
                  <h4>{t('admin_campaign_details:participating_groups')}</h4>
                  <div className="name_tag_container">
                    {Object.keys(campaign?.groups ? campaign?.groups : {}).map(
                      group => (
                        <span className="name_tag" key={group}>
                          <span className="tag">
                            {campaign?.groups[group].name}
                          </span>
                        </span>
                      )
                    )}
                  </div>
                </div>
              )}
              <div>
                <h4>{t('admin_campaign_details:people')}</h4>
                <div className="people-tags">
                  {allParticipants.slice(0, 5).map((participant, index) => (
                    <img
                      alt="userPhoto"
                      key={index}
                      src={participant.photo || EmployeeAvatar}
                      className="circle_people"
                      onError={evt => addDefaultPicture(evt)}
                    />
                  ))}
                  {allParticipants.slice(4, allParticipants.length).length >
                    0 && (
                    <div className="circle-tag plus-tag">
                      +{allParticipants.slice(5, allParticipants.length).length}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className="action-button">
                  <Icon size="small" name="circle-info" color="#05559C" />
                  <a href={`/campaigns/admin/${campaign?.id}/edit`}>
                    {t('admin_campaign_details:campaign_details')}
                  </a>
                  {campaign?.status === 'activated' && (
                    <>
                      <div className="vl"></div>
                      <Icon size="small" name="whatsapp" color="#05559C" />
                      <a
                        href={`https://api.whatsapp.com/send/?phone&text=${t(
                          'campaign_card:new_campaign_avaiable'
                        )}-https://${subdomain}.advocatespro.com/campaigns/${
                          campaign?.id
                        }`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('admin_campaign_details:share_whatsapp')}
                      </a>
                    </>
                  )}
                  {campaign?.notifiedReminded === false &&
                    campaign?.status === 'activated' && (
                      <>
                        <div className="vl"></div>
                        <Icon size="small" name="bell" color="#05559C" />
                        <span onClick={() => manualReport('campaign')}>
                          {t('admin_campaign_details:send_reminder')}
                        </span>
                      </>
                    )}
                </div>
              </div>
            </div>
          </HeardMargin>
          <h1>{t('admin_campaign_details:summary')}</h1>
          <BodyWrapper>
            <GridWrapper className="admin-campaign-detail">
              <StyledTooltipWrapper visible={false}>
                <SingleDataBox>
                  <MetricImgBox
                    iconName={iconPerson}
                    value={
                      campaign?.ambassadorsParticipating +
                      ' / ' +
                      campaign?.ambassadorsWithConnectedNetworks
                    }
                    text={t(
                      'admin_campaign_details:ambassadors_generate_content'
                    )}
                  />
                </SingleDataBox>
              </StyledTooltipWrapper>

              <StyledTooltipWrapper visible={false}>
                <SingleDataBox>
                  <NetworkParticipationBox
                    onConnect={() => alert('Maybe some other time')}
                    items={networksBoxItems}
                    detailCampaign={true}
                  />
                </SingleDataBox>
              </StyledTooltipWrapper>

              <StyledTooltipWrapper visible={false}>
                <SingleDataBox>
                  <MetricBox
                    iconName="report"
                    value={`${nFormatter(campaign?.totalParticipation)}`}
                    text={t('campaign_card:content_general')}
                  />
                </SingleDataBox>
              </StyledTooltipWrapper>

              <StyledTooltipWrapper
                visible={visible.totalValorization}
                onMouseOver={() => changeTooltip('totalValorization', true)}
                onMouseOut={() => changeTooltip('totalValorization', false)}
                className="tooltip-wrapper"
              >
                <SingleDataBox>
                  <MetricImgBox
                    iconName={iconMoney}
                    value={`${company?.currency?.sign}${nFormatter(
                      campaign?.totalValorization
                    )}`}
                    money={company?.currency?.code}
                    text={t('campaign_card:valorization_campaign')}
                  />
                  <TooltipMessage>
                    {t('campaign_card:total_valorization_tooltip')}
                  </TooltipMessage>
                </SingleDataBox>
              </StyledTooltipWrapper>
            </GridWrapper>
            <GridWrapper
              className={
                campaign?.totalViews > 0
                  ? 'admin-campaign-detail '
                  : 'admin-campaign-detail second'
              }
            >
              <StyledTooltipWrapper
                visible={visible.followersAccountsParticipating}
                onMouseOver={() =>
                  changeTooltip('followersAccountsParticipating', true)
                }
                onMouseOut={() =>
                  changeTooltip('followersAccountsParticipating', false)
                }
                className="tooltip-wrapper"
              >
                <SingleDataBox>
                  <MetricBox
                    iconName="total-reach"
                    value={`${nFormatter(campaign?.potentialReach)}`}
                    text={t('campaign_card:potencial_reach')}
                  />
                  <TooltipMessage>
                    {t(
                      'campaign_card:followers_accounts_participating_tooltip'
                    )}
                  </TooltipMessage>
                </SingleDataBox>
              </StyledTooltipWrapper>

              <StyledTooltipWrapper
                visible={visible.estimatedFollowers}
                onMouseOver={() => changeTooltip('estimatedFollowers', true)}
                onMouseOut={() => changeTooltip('estimatedFollowers', false)}
                className="tooltip-wrapper"
              >
                <SingleDataBox>
                  <MetricBox
                    iconName="report"
                    value={`${nFormatter(campaign?.estimatedReach)}`}
                    text={t('campaign_card:estimated_reach')}
                  />
                  <TooltipMessage>
                    {t('campaign_card:estimated_followers_tooltip')}
                  </TooltipMessage>
                </SingleDataBox>
              </StyledTooltipWrapper>

              <StyledTooltipWrapper
                visible={visible.engagements}
                onMouseOver={() => changeTooltip('engagements', true)}
                onMouseOut={() => changeTooltip('engagements', false)}
                className="tooltip-wrapper"
              >
                <SingleDataBox>
                  <MetricImgBox
                    iconName={iconHeard}
                    value={`${nFormatter(campaign?.engagements)}`}
                    text={t('campaign_card:engagements')}
                  />
                  <TooltipMessage>
                    {t('campaign_card:engagements_tooltip')}
                  </TooltipMessage>
                </SingleDataBox>
              </StyledTooltipWrapper>
              {campaign?.totalViews > 0 && (
                <StyledTooltipWrapper
                  visible={visible.visualizations}
                  onMouseOver={() => changeTooltip('visualizations', true)}
                  onMouseOut={() => changeTooltip('visualizations', false)}
                  className="tooltip-wrapper"
                >
                  <SingleDataBox>
                    <MetricImgBox
                      iconName={iconHeard}
                      value={`${nFormatter(campaign?.totalViews)}`}
                      text={t('campaign_card:visualizations')}
                    />
                    <TooltipMessage>
                      {t('campaign_card:visualizations_tooltip')}
                    </TooltipMessage>
                  </SingleDataBox>
                </StyledTooltipWrapper>
              )}
            </GridWrapper>
          </BodyWrapper>
          <h1>{t('campaign_card:campaign_summary')}</h1>
          <InputStyled>
            <Input
              type="search"
              placeholder={t('admin_campaign_details:search')}
              value={name}
              onChange={event => {
                setName(event.target.value);
              }}
            />
          </InputStyled>
          <TextStyled>
            {t('admin_campaign_details:ambassadors_list')}
          </TextStyled>
          <HeardMargin>
            <div className="option">
              <img alt="iconEyes" src={iconEyes} />
              <TextStyled>{t('admin_campaign_details:see_post')}</TextStyled>
            </div>
            <div className="option">
              <img alt="eyeStrikethroughRed" src={eyeStrikethroughRed} />
              <TextStyled>{t('admin_campaign_details:delete_post')}</TextStyled>
            </div>
            <div className="option">
              <img alt="programmingPost" src={programmingPost} />
              <TextStyled>
                {t('admin_campaign_details:programming_post')}
              </TextStyled>
            </div>
            <div className="option">
              <img alt="iconNoAction" src={iconNoAction} />
              <TextStyled>
                {t('admin_campaign_details:without_actions')}
              </TextStyled>
            </div>
            <div className="option">
              <img alt="iconStar" src={iconStar} />
              <TextStyled>
                {t('admin_campaign_details:action_points')}
              </TextStyled>
            </div>
          </HeardMargin>
          <ResumenScrollContainer>
            <ResumenTable
              role="admin"
              employees={currentRanking}
              currentEmployeeId={currentUser.user.id}
              badPracticesOnClickFunction={confirmAlert}
              includesFacebook={campaign?.includesFacebook}
              includesTwitter={campaign?.includesTwitter}
              includesLinkedin={campaign?.includesLinkedin}
              includesInstagram={campaign?.includesInstagram}
              includesWhatsapp={campaign?.includesWhatsapp}
              includesTiktok={campaign?.includesTiktok}
            />
            <TableFooter
              postsPerPage={postsPerPage}
              totalPosts={allParticipants?.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </ResumenScrollContainer>
          <ButtonStyled>
            <Button
              onClick={() => downloadReport('CAMPAIGN')}
              value={t('admin_campaign_details:download_report')}
              variant="primary"
            />
            {company?.company_permission?.allowReportParticipation && (
              <Button
                onClick={() => downloadReport('PARTICIPATIONS_BY_CAMPAIGN')}
                value={t(
                  'admin_campaign_details:download_participation_report'
                )}
                variant="primary"
              />
            )}
          </ButtonStyled>
        </SectionWrapper>
      </Suspense>
    </>
  );
};

export default CampaignsAdminDetail;
