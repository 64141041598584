import React from 'react';
import { useTranslation } from 'react-i18next';

import Tabs from 'components/Tabs';

import SectionWrapper from 'pages/Layout/SectionWrapper';
import EditCompany from './EditCompany';
import ChangePermissions from './ChangePermissions';
import { useSelector } from 'react-redux';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import CompanyLogs from './LogsCompany';

const CompanySettings = () => {
  const { t } = useTranslation(['settings']);
  const { company } = useSelector(selectCompany);
  return (
    <SectionWrapper>
      <h1>{t('settings')}</h1>
      <Tabs
        orientation="vertical"
        tabsTitle={[
          { id: 1, label: company?.name },
          { id: 2, label: t('permissions') },
          { id: 3, label: t('logs') }
        ]}
        tabsContent={[
          { id: 1, children: <EditCompany /> },
          { id: 2, children: <ChangePermissions /> },
          { id: 3, children: <CompanyLogs /> }
        ]}
      />
    </SectionWrapper>
  );
};

export default CompanySettings;
