import React, { Suspense, useEffect } from 'react';
import Tag from 'components/Tag';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { Facebook as LoadingState } from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import { FacebookAlbum } from './Styled';
import { selectUser } from 'redux/slices/userSlice';
import {
  selectPerformance,
  setLoading,
  setPerformance
} from 'redux/slices/PerformanceSection/PerformanceSectionSlice';
import { getAmbassadorPerformance } from 'services/rest/PerformanceSection/PerformanceSection';
import { useFetch } from 'hooks/useFetch.hook';
import { getInfoUser } from 'services/rest/User/getInfoUser';

const FacebookSection = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(selectUser);
  const { loading, performanceSection } = useSelector(selectPerformance);
  const { data } = useFetch(
    getInfoUser(currentUser?.user?.id, currentUser?.token, currentUser?.role)
  );
  const userData = data as any;

  const getData = async () => {
    const { data } = await getAmbassadorPerformance(currentUser?.token);
    dispatch(setPerformance(data.data));
    dispatch(setLoading(false));
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getData();
  }, []);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const CustomFacebookView = ({ data }: { data: any }) => {
    return (
      <FacebookAlbum>
        {data.map((post: any, index: number) => (
          <a
            href={post?.permalinkUrl}
            target="_blank"
            rel="noreferrer"
            key={index}
          >
            <div className="fb-post">
              <div className="header">
                <img
                  alt="userPhoto"
                  className="img-user"
                  src={post?.picture}
                ></img>
                <div className="texts">
                  <p className="username">{post?.username}</p>
                  <p className="message">
                    {post?.message && post?.message.length > 50
                      ? `${post?.message.slice(0, 50)}...`
                      : post?.message}
                  </p>
                </div>
              </div>
              <img alt="imgPost" className="img-post" src={post?.url}></img>
            </div>
          </a>
        ))}
      </FacebookAlbum>
    );
  };

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <SectionWrapper>
        <Tag network="facebook" variant="network" />
        {!userData?.linkedFacebook ? (
          <h1>To view Facebook posts you must connect your account</h1>
        ) : (
          performanceSection?.albumFacebook && (
            <CustomFacebookView
              data={performanceSection?.albumFacebook}
            ></CustomFacebookView>
          )
        )}
      </SectionWrapper>
    </Suspense>
  );
};
export default FacebookSection;
