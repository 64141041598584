import React, { Suspense, useEffect, useState } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { getCompanyMetrics } from 'services/rest/CompanyMetrics/CompanyMetrics';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import {
  selectCompanyMetrics,
  setLoading,
  setMetrics
} from 'redux/slices/CompanyMetrics/CompanyMetricsSlice';
import SingleDataBox from 'components/boxes/SingleDataBox';
import MetricBox from 'components/boxes/MetricBox';
import NetworkParticipationBox from 'components/boxes/NetworkParticipationBox';
import { MetricsWrapper, ToggleMetricsWrapper } from './Styled';
import {
  StyledTooltipWrapper,
  TooltipMessage
} from 'components/TooltipWrapper/StyledTooltipWrapper';
import MetricImgBox from 'components/boxes/MetricBoxImg';
import iconActivated from 'images/icons/bonus_approved.svg';
import iconCompleted from 'images/icons/completed.svg';
import iconHeart from 'images/icons/heart.svg';
import iconMoney from 'images/icons/money.svg';
import { nFormatter } from 'helpers/formatHelper';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import { Button } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const MetricsChallengeSectionAdmin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['challenge']);
  const { currentUser } = useSelector(selectUser);
  const { token } = currentUser;
  const { loading, companyMetrics } = useSelector(selectCompanyMetrics);
  const { company } = useSelector(selectCompany);
  const [expandMetrics, setExpandMetrics] = useState(false);
  const [visible, setVisible] = useState({
    totalValorization: false,
    potential_reach: false,
    estimated_reach: false,
    totalContentsChallenges: false
  });

  const handleClickToggle = () => setExpandMetrics(prev => !prev);

  const getData = async () => {
    const data = await getCompanyMetrics({
      token,
      typeFilter: 'challenges'
    });
    dispatch(setMetrics(data));
    dispatch(setLoading(false));
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getData();
  }, []);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const changeTooltip = (tooltipType: string, state: boolean) => {
    const tooltipTypes = {
      ...visible,
      [tooltipType]: state
    };
    setVisible(tooltipTypes);
  };

  const networksBoxItems = [
    {
      linked: true,
      network: 'facebook',
      percentage: companyMetrics?.percentageChallenges?.facebook
    },
    {
      linked: true,
      network: 'twitter',
      percentage: companyMetrics?.percentageChallenges?.twitter
    },
    {
      linked: true,
      network: 'linkedin',
      percentage: companyMetrics?.percentageChallenges?.linkedin
    },
    {
      linked: true,
      network: 'instagram',
      percentage: companyMetrics?.percentageChallenges?.instagram
    },
    {
      linked: true,
      network: 'tiktok',
      percentage: companyMetrics?.percentageChallenges?.tiktok
    },
    {
      linked: true,
      network: 'snapchat',
      percentage: companyMetrics?.percentageChallenges?.snapchat
    },
    {
      linked: true,
      network: 'youtube',
      percentage: companyMetrics?.percentageChallenges?.youtube
    },
    {
      linked: true,
      network: 'threads',
      percentage: companyMetrics?.percentageChallenges?.threads
    },
    {
      linked: true,
      network: 'otros',
      percentage: companyMetrics?.percentageChallenges?.others
    }
  ];

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <MetricsWrapper>
        <StyledTooltipWrapper visible={false}>
          <SingleDataBox>
            <MetricBox
              iconName={'report'}
              value={`${companyMetrics?.totalChallengesActivated} / ${companyMetrics?.totalChallenges}`}
              text={t('challenge:active_total_challenges')}
            />
          </SingleDataBox>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper
          visible={visible?.totalContentsChallenges}
          onMouseOver={() => changeTooltip('totalContentsChallenges', true)}
          onMouseOut={() => changeTooltip('totalContentsChallenges', false)}
        >
          <SingleDataBox>
            <MetricImgBox
              iconName={iconCompleted}
              value={companyMetrics?.totalContentsChallenges}
              text={t('challenge:total_contents_approved')}
            />
          </SingleDataBox>
          <TooltipMessage>
            {t('challenge:contents_of_challenges_tooltip')}
          </TooltipMessage>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper visible={false}>
          <SingleDataBox>
            <MetricImgBox
              iconName={iconActivated}
              value={companyMetrics?.totalAmbassadorsParticipating}
              text={t('challenge:ambassadors_participating')}
            />
          </SingleDataBox>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper
          visible={visible.totalValorization}
          onMouseOver={() => changeTooltip('totalValorization', true)}
          onMouseOut={() => changeTooltip('totalValorization', false)}
        >
          <SingleDataBox>
            <MetricImgBox
              iconName={iconMoney}
              value={`${company?.currency?.sign}${nFormatter(
                companyMetrics?.totalValorization
              )}`}
              money={company?.currency?.code}
              text={t('challenge:total_valorization_plural')}
            />
            <TooltipMessage>
              {t('challenge:total_valorization_tooltip')}
            </TooltipMessage>
          </SingleDataBox>
        </StyledTooltipWrapper>

        {expandMetrics && (
          <>
            <StyledTooltipWrapper
              visible={visible.potential_reach}
              onMouseOver={() => changeTooltip('potential_reach', true)}
              onMouseOut={() => changeTooltip('potential_reach', false)}
            >
              <SingleDataBox>
                <MetricBox
                  iconName="total-reach"
                  value={`${nFormatter(companyMetrics?.totalPotentialReach)}`}
                  text={t('challenge:potential_reach_plural')}
                />
                <TooltipMessage>
                  {t('challenge:potential_reach_tooltip')}
                </TooltipMessage>
              </SingleDataBox>
            </StyledTooltipWrapper>

            <StyledTooltipWrapper
              visible={visible.estimated_reach}
              onMouseOver={() => changeTooltip('estimated_reach', true)}
              onMouseOut={() => changeTooltip('estimated_reach', false)}
            >
              <SingleDataBox>
                <MetricImgBox
                  iconName={iconHeart}
                  value={`${nFormatter(companyMetrics?.totalEstimatedReach)}`}
                  text={t('challenge:estimated_reach_plural')}
                />
                <TooltipMessage>
                  {t('challenge:estimated_reach_tooltip')}
                </TooltipMessage>
              </SingleDataBox>
            </StyledTooltipWrapper>

            <StyledTooltipWrapper visible={false}>
              <SingleDataBox>
                <NetworkParticipationBox
                  onConnect={() => alert('Maybe some other time')}
                  items={networksBoxItems}
                  msgTopText={t('challenge:challenge_for_social_network')}
                  columns={2}
                />
              </SingleDataBox>
            </StyledTooltipWrapper>
          </>
        )}
      </MetricsWrapper>

      <ToggleMetricsWrapper>
        <Button
          onClick={handleClickToggle}
          endIcon={expandMetrics ? <ExpandLess /> : <ExpandMore />}
        >
          {expandMetrics ? t('challenge:see_less') : t('challenge:see_more')}
        </Button>
      </ToggleMetricsWrapper>
    </Suspense>
  );
};

export default MetricsChallengeSectionAdmin;
