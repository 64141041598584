import React, { useState } from 'react';
import { Colors } from 'styles/Constants';
import Swal from 'sweetalert2';
import Button from 'components/buttons/Button';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'components/progress_bar/ProgressBar';
import SocialMediaIcon from 'components/icons/SocialMediaIcon';
import { useLocation } from 'react-router-dom';

import { setRouterCurrent } from 'redux/slices/Router/RouterSlice';
import StyledNetworkParticipationBox, {
  StyledButtonCollection
} from './StyledNetworkParticipationBox';
import { postNetworkToken } from 'services/rest/Network/Network';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import { urlFacebook, urlLinkedin } from 'constants/setup.constants';
import { TwitterToken } from 'services/rest/Network/twitterToken';
import { TiktokToken } from 'services/rest/Network/getAuthToken';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUser,
  setUserInfo,
  setUserInfoLoading
} from 'redux/slices/userSlice';
import { getInfoUser } from 'services/rest/logout';
import InstagramLogo from 'images/logos/logo-instragram.png';

export interface NetworkProps {
  network: string;
  percentage: string;
  linked: boolean;
}

export interface NetworkParticipationBoxProps {
  items: Array<NetworkProps>;
  onConnect: (network: string) => void;
  topText?: boolean;
  msgTopText?: string;
  columns?: number;
  detailCampaign?: boolean;
}

const NetworkParticipationBox: React.FC<NetworkParticipationBoxProps> = props => {
  const { t } = useTranslation(['network_participation']);
  const { items, topText, detailCampaign = false } = props;
  const unLinkedNetworks = items.filter(item => item.linked === false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { company } = useSelector(selectCompany);
  const { currentUser } = useSelector(selectUser);
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  dispatch(setRouterCurrent(pathname));

  const postData = async (
    network: string,
    token: string,
    secret: string,
    verifier: string,
    screenName: string
  ) => {
    await postNetworkToken(
      currentUser?.token,
      {
        token: token,
        followers: 1,
        photo: '',
        uid: 1,
        secret: secret,
        verifier: verifier,
        screenName: screenName
      },
      network
    )
      .then(response => {
        dispatch(setUserInfoLoading(true));
        if (response.data) {
          getInfoUser(currentUser?.user?.id, currentUser?.token).then(
            response => {
              dispatch(setUserInfo(response.data));
              dispatch(setUserInfoLoading(false));
            }
          );
        } else {
          setErrorMessage(t('networks_list:error_connect'));
        }
      })
      .catch(error => {
        dispatch(setUserInfoLoading(false));
      });
  };

  const connect = (conect: string) => {
    switch (conect) {
      case 'facebook':
        window.location.href = urlFacebook(
          company?.facebookCredentials.apiKey,
          conect
        );

        break;
      case 'twitter':
        TwitterToken(currentUser?.token)
          .then(response => (window.location.href = response.data.data))
          .catch(error => console.log(error));

        break;
      case 'instagram':
        Swal.fire({
          color: Colors.secondaryDarkBlue,
          width: 400,
          imageUrl: InstagramLogo,
          imageWidth: 200,
          imageHeight: 100,
          input: 'text',
          showCloseButton: true,
          inputLabel: t('social_network:UserName'),
          confirmButtonText: t('networks_list:connect'),
          confirmButtonColor: Colors.secondaryPurple
        }).then(result => {
          if (result.value != '') {
            if (result.value != undefined) {
              postData(conect, 'code', '', '', result.value);
            }
          }
        });
        break;
      case 'linkedin':
        window.location.href = urlLinkedin(
          company?.linkedinCredentials.apiKey,
          conect
        );

        break;
      case 'tiktok':
        TiktokToken(currentUser?.token)
          .then(response => (window.location.href = response.data.data))
          .catch(error => console.log(error));

        break;
    }
  };

  return (
    <StyledNetworkParticipationBox {...props}>
      {topText && (
        <h3>
          {props.msgTopText ??
            t('network_participation:contents_by_social_network')}
        </h3>
      )}

      <div style={{ columns: `${props.columns ?? 1}` }}>
        {items.map(({ network, percentage, linked }, index) => {
          return (
            <ProgressBar
              key={index}
              withNumber={true}
              color={Colors[network]}
              percentage={
                percentage !== undefined && percentage !== null
                  ? percentage
                  : '0'
              }
              icon={
                <SocialMediaIcon
                  name={network === 'others' ? 'otros' : network}
                  size="small"
                />
              }
              disabled={!linked}
              detailCampaign={detailCampaign}
            />
          );
        })}
      </div>

      {!topText && (
        <h3>
          {props.msgTopText ??
            t('network_participation:contents_by_social_network')}
        </h3>
      )}

      <StyledButtonCollection className="button-collection">
        {!detailCampaign &&
          unLinkedNetworks.map(({ network }, index) => (
            <div
              className={network}
              key={index}
              onClick={() => connect(network)}
            >
              <Button
                size="small"
                icon={network}
                variant={network}
                value={t('network_participation:link_account')}
              />
            </div>
          ))}
      </StyledButtonCollection>
    </StyledNetworkParticipationBox>
  );
};

export default NetworkParticipationBox;
