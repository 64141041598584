import Client from 'config/axios';

export const updateContentUrl = async (
  token: string,
  campaignId: number,
  provider: string,
  content: string
) => {
  const body = {
    campaignId,
    provider,
    content
  };
  try {
    const { data } = await Client.post(`shorten-content-links`, body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return { data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
