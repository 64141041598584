import styled from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';
import BaseBox from 'components/boxes/BaseBox';
import { StyledNetworkParticipationBox } from 'components/boxes/NetworkParticipationBox';

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-top: 20px;

  @media (min-width: ${Breakpoints.medium}) {
    grid-column-gap: 20px;
  }

  > div:nth-of-type(-n + 4),
  > div:nth-of-type(n + 5),
  > div:nth-of-type(n + 6) {
    grid-column: span 3;
    margin-bottom: 20px;
  }
  > div:nth-of-type(n + 7) {
    grid-column: span 6;
    margin-bottom: 20px;
  }

  .single-data-box {
    display: grid;
    margin-bottom: 0px;
    height: 100%;
  }

  .currency {
    color: #004fea;
    margin-top: -25px;
    font-weight: 700;
  }

  svg[name='flag'] {
    stroke-width: 30px;
    stroke: ${Colors.primaryDarkBlue};
  }
`;

export const ToggleMetricsWrapper = styled.div`
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-end;

  button {
    color: ${Colors.primaryDarkBlue};
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.42857;
  }
`;
