import Client from 'config/axios';

export const getCampaignDataById = async (
  token: string,
  id: any,
  ranking: boolean,
  withParticipation: boolean
) => {
  try {
    const response = await Client.get(
      `campaign/${id}?ranking=${ranking}&withParticipation=${withParticipation}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error.response || error.message);
  }
};
