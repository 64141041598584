export const urlFacebook = (client_id: string, network: string) => {
  const { origin } = window.location;
  return `https://www.facebook.com/dialog/oauth?redirect_uri=${origin}/${network}/callback&response_type=token&scope=email,public_profile,user_posts,user_friends,user_link&client_id=${client_id}`;
};

export const urlLinkedin = (client_id: string, network: string) => {
  const { origin } = window.location;
  return `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${origin}/${network}/callback&scope=r_basicprofile,r_emailaddress,w_member_social,w_organization_social,r_organization_social,r_1st_connections_size,rw_ads,r_ads`;
};

export const urlTwitterAutorize = (oauth_token: string) =>
  `${process.env.REACT_APP_URL_TWITTER_API}/oauth/authorize?oauth_token=${oauth_token}`;

export const getUrlS3 = (type: string, name: string, subdomain?: string) => {
  return subdomain
    ? `${subdomain}/${name}`
    : `${process.env.REACT_APP_URL_IMG_S3}${type}/${name}`;
};

export const getUrlVideoS3 = (type: string, name: string) => {
  return `${process.env.REACT_APP_URL_VIDEO_S3}${type}/${name}`;
};
