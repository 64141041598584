import React from 'react';
import { useTranslation } from 'react-i18next';
import PostPointsBox from 'components/boxes/PostPointsBox';
import PostDetail from './PostDetail';

import {
  Points,
  Post,
  FacebookPoints,
  TwitterPoints,
  LinkedinPoints,
  InstagramPoints,
  WhatsappPoints
} from 'types/appTypes';
import { MobileCard } from './mobileCard';
import { ICampaigns } from 'redux/slices/Campaigns/Campaigns.interface';

export interface PointsTableProps {
  provider: string;
  sncPoints: Points;
  originalContent: string;
  originalContent2?: string;
  originalContent3?: string;
  campaignStatus: string;
  post: Post;
  getRankingData?: any;
  campaign?: ICampaigns;
}

export type NetworkPoints = {
  sncPoints: Points;
  postPoints: Points;
};

const useFacebookPoints = (points: NetworkPoints) => {
  const sncPoints = points?.sncPoints as FacebookPoints;
  const postPoints = points?.postPoints as FacebookPoints;

  const { t } = useTranslation(['points_table']);

  return [
    {
      action: t('publishing'),
      actionPoints: sncPoints?.publish,
      earnedPoints: postPoints?.publish
    },
    {
      action: t('facebook.reaction'),
      actionPoints: sncPoints?.reactions,
      earnedPoints: postPoints?.reactions
    },
    {
      action: t('facebook.comments'),
      actionPoints: sncPoints?.comments,
      earnedPoints: postPoints?.comments
    },
    {
      action: t('facebook.shared'),
      actionPoints: sncPoints?.shared,
      earnedPoints: postPoints?.shared
    }
  ];
};

const useTwitterPoints = (points: NetworkPoints) => {
  const sncPoints = points?.sncPoints as TwitterPoints;
  const postPoints = points?.postPoints as TwitterPoints;

  const { t } = useTranslation(['points_table']);

  return [
    {
      action: t('publishing'),
      actionPoints: sncPoints?.publish,
      earnedPoints: postPoints?.publish
    },
    {
      action: t('twitter.reactions'),
      actionPoints: sncPoints?.reactions,
      earnedPoints: postPoints?.reactions
    },
    {
      action: t('twitter.comments'),
      actionPoints: sncPoints?.comments,
      earnedPoints: postPoints?.comments
    },
    {
      action: t('twitter.shared'),
      actionPoints: sncPoints?.shared,
      earnedPoints: postPoints?.shared
    }
  ];
};

const useLinkedinPoints = (points: NetworkPoints) => {
  const sncPoints = points?.sncPoints as LinkedinPoints;
  const postPoints = points?.postPoints as LinkedinPoints;

  const { t } = useTranslation(['points_table']);

  return [
    {
      action: t('publishing'),
      actionPoints: sncPoints?.publish,
      earnedPoints: postPoints?.publish
    },
    {
      action: t('linkedin.reactions'),
      actionPoints: sncPoints?.reactions,
      earnedPoints: postPoints?.reactions
    },
    {
      action: t('linkedin.comments'),
      actionPoints: sncPoints?.comments,
      earnedPoints: postPoints?.comments
    }
  ];
};

const useInstagramPoints = (points: NetworkPoints) => {
  const sncPoints = points?.sncPoints as InstagramPoints;
  const postPoints = points?.postPoints as InstagramPoints;

  const { t } = useTranslation(['points_table']);

  return [
    {
      action: t('publishing'),
      actionPoints: sncPoints?.publish,
      earnedPoints: postPoints?.publish
    }
  ];
};

const useWhatsappPoints = (points: NetworkPoints) => {
  const sncPoints = points?.sncPoints as WhatsappPoints;
  const postPoints = points?.postPoints as WhatsappPoints;

  const { t } = useTranslation(['points_table']);

  return [
    {
      action: t('publishing'),
      actionPoints: sncPoints?.publish,
      earnedPoints: postPoints?.publish
    }
  ];
};

const usePointsList = (provider: string, otherProps: NetworkPoints) => {
  switch (provider) {
    case 'facebook':
      return useFacebookPoints(otherProps);
    case 'twitter':
      return useTwitterPoints(otherProps);
    case 'linkedin':
      return useLinkedinPoints(otherProps);
    case 'instagram':
      return useInstagramPoints(otherProps);
    case 'whatsapp':
      return useWhatsappPoints(otherProps);
    default:
      return useFacebookPoints(otherProps);
  }
};

const PointsTable: React.FC<PointsTableProps> = props => {
  const {
    post: {
      id,
      totalPoints,
      status,
      realPostUrl,
      detailPoints,
      networkFlagParticipation,
      socialNetworkAccount
    },
    sncPoints,
    provider,
    originalContent,
    originalContent2,
    originalContent3,
    campaignStatus,
    getRankingData,
    campaign
  } = props;

  const points = usePointsList(provider, {
    sncPoints,
    postPoints: detailPoints
  });

  if (
    (provider == 'instagram' || provider == 'whatsapp') &&
    status !== 'published'
  ) {
    return <MobileCard provider={provider} />;
  } else {
    return (
      <PostPointsBox
        points={points}
        totalPoints={totalPoints}
        provider={provider}
      >
        <PostDetail
          id={id}
          status={status}
          provider={provider}
          realPostUrl={realPostUrl}
          originalContent={originalContent}
          originalContent2={originalContent2}
          originalContent3={originalContent3}
          campaignStatus={campaignStatus}
          getRankingData={getRankingData}
          networkFlagParticipation={networkFlagParticipation}
          socialNetworkAccount={socialNetworkAccount}
          campaign={campaign}
          programmerPost={false}
        />
      </PostPointsBox>
    );
  }
};

export default PointsTable;
