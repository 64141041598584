import React from 'react';
import TwitterText from 'twitter-text';
import cx from 'classnames';
import { StyledCounter } from './Styled';
interface CounterProps {
  maxCharacters: number;
  text: string;
  isTwitter: boolean;
}
const Counter: React.FC<CounterProps> = props => {
  const { maxCharacters, text, isTwitter } = props;
  const remainingCharacters = isTwitter
    ? maxCharacters - TwitterText.getTweetLength(text)
    : maxCharacters - countCharacter(text);

  function countCharacter(text: string, weight = 35) {
    const urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
    const countPattern = ((text || '').match(urlPattern) || []).length;
    if (urlPattern.test(text)) {
      return (
        text.replace(/(?:https?|ftp):\/\/\S+|www\.\S+/g, ' ').trim().length +
        weight * countPattern
      );
    } else {
      return text.length;
    }
  }
  return (
    <StyledCounter
      className={cx({ 'max-chars-error': remainingCharacters < 0 })}
    >
      {remainingCharacters}
    </StyledCounter>
  );
};
export default Counter;
