import styled from 'styled-components';
import { Colors, Breakpoints } from 'styles/Constants';
import BaseBox from 'components/boxes/BaseBox';

export const MetricsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-top: 20px;

  @media (min-width: ${Breakpoints.medium}) {
    grid-column-gap: 20px;
  }

  > div:nth-of-type(-n + 4),
  > div:nth-of-type(n + 5),
  > div:nth-of-type(n + 6) {
    grid-column: span 3;
    margin-bottom: 20px;
  }
  > div:nth-of-type(n + 7) {
    grid-column: span 6;
    margin-bottom: 20px;
  }

  .single-data-box {
    display: grid;
    margin-bottom: 0px;
    height: 100%;
  }
  .currency {
    color: #004fea;
    margin-top: -25px;
    font-weight: 700;
  }

  svg[name='flag'] {
    stroke-width: 30px;
    stroke: ${Colors.primaryDarkBlue};
  }
`;

export const CardWrapper = styled.div`
  @media only screen and (max-width: 900px) {
    width: 120%;
  }

  ${BaseBox};

  @media (min-width: ${Breakpoints.medium}) {
    height: 150px;
  }

  .content-img {
    width: 150px;
    min-height: inherit !important;
    display: flex !important;
    align-items: center !important;
  }

  img {
    width: 100%;
    height: inherit;
    object-fit: contain;
  }

  display: grid;
  grid-template-columns: 200px 4fr 6fr 2fr;

  .time-line {
    margin: 0 0 5px 0;
    font-weight: 500;
    line-height: 1.5;
    font-size: 14px;
    color: ${Colors.primaryNormalPurple};
    text-transform: capitalize;
  }

  .social-networks {
    display: flex;
    justify-content: left;
    margin-top: 10px;
  }

  .campaign-stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
`;

export const MarginDiv = styled.div`
  margin-bottom: 0px;
  font-size: 0.6875rem;
  text-align: center;

  @media only screen and (max-width: 800px) {
    width: 80px;
  }

  p {
    text-align: center;
    color: #004fea;
    font-size: 30px;
    font-weight: 700;
    line-height: 1;
    margin: 0 0 5px 0;
  }

  h3 {
    width: 200px;
  }

  &.division-right {
    position: relative;
  }

  &.division-right:before {
    content: '';
    background-color: #d8d8d8;
    position: absolute;
    border-radius: 8px;
    height: 100%;
    width: 4px;
    right: 0px;
  }

  @media (min-width: ${Breakpoints.medium}) {
    p {
      font-size: 3.125rem;
    }
  }
`;

export const MarginText = styled.div`
padding: 5px;
font-weight: 500;
font-size: 14px;
cursor: pointer;
&.m-auto{
  margin: auto 0px;
}
a { 
  text-decoration: none;
}
div {
  h2 {
    font-weight: bold;
    position: relative;
    font-size: 18px;
    margin: 0;
    color: #000;
  }
}
.overflow-elipsis{
  height: 100%;
  h2{
    height: 50px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

}
`;

export const ChallengeSection = styled.div`
  margin: 0px 0px 75px;
`;

export const DivIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  img {
    width: 25px;
    margin: 5px;
  }
`;

export const MarginAdminPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-size: 20px;
    color: #4d4d4d;
    font-weight: bold;
  }

  p {
    font-size: 28px;
    color: ${Colors.primaryGray};
    font-weight: bold;
  }

  button {
    font-size: 1.125rem;
    height: 20%;
    margin-top: 20px;
    border: 1px solid #d113fe;
    background-color: #d113fe;
  }
`;

export const GridWrapper = styled.div`
  @media only screen and (max-width: 900px) {
    width: 120%;
  }

  ${BaseBox};

  @media (min-width: ${Breakpoints.medium}) {
    height: 150px;
  }

  .content-img a {
    width: 150px;
    height: 150px;
    min-height: inherit !important;
    display: flex !important;
    align-items: center !important;
  }

  img {
    width: 100%;
    height: inherit;
    object-fit: contain;
  }

  display: grid;
  grid-template-columns: 200px 4fr 6fr 2fr;

  .time-line {
    margin: 0 0 5px 0;
    font-weight: 500;
    line-height: 1.5;
    font-size: 14px;
    color: ${Colors.primaryNormalPurple};
    text-transform: capitalize;
  }

  .campaign-stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
  .campaign-desc {
    position: relative;
  }
  .campaign-desc h3 {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
`;

export const ToggleMetricsWrapper = styled.div`
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-end;

  button {
    color: ${Colors.primaryDarkBlue};
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.42857;
  }
`;
