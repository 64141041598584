import React, { Fragment, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/buttons/Button';
import { ButtonsParticipations, PostDetailWrapper } from './Style';

import ContentEditor from '../ContentEditor';
import ContentTiktok from '../ContentTiktok';
import FacebookShareCard from '../FacebookShareCard';
import ThreeDots from 'components/loaders/ThreeDots';
import { useDispatch, useSelector } from 'react-redux';
import { urlFacebook, urlLinkedin } from 'constants/setup.constants';
import { TwitterToken } from 'services/rest/Network/twitterToken';
import { TiktokToken } from 'services/rest/Network/getAuthToken';
import {
  selectUser,
  setUserInfo,
  setUserInfoLoading
} from 'redux/slices/userSlice';
import Swal from 'sweetalert2';
import { Colors } from 'styles/Constants';
import InstagramLogo from 'images/logos/logo-instragram.png';
import { postNetworkToken } from 'services/rest/Network/Network';
import { getInfoUser } from 'services/rest/logout';
import { setInvalidTokenCurrent } from 'redux/slices/InvalidToken/InvalidTokenSlice';
import Spinner from 'components/Spinner/Spinner';
import { ICampaigns } from 'redux/slices/Campaigns/Campaigns.interface';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import { updateContentUrl } from 'services/rest/Campaign/updateContentUrl';

export interface PostDetailProps {
  id: number;
  status: string;
  provider: string;
  realPostUrl: string;
  originalContent: string;
  originalContent2?: string;
  originalContent3?: string;
  campaignStatus: string;
  setShowButton?: any;
  getRankingData?: any;
  networkFlagParticipation?: boolean;
  socialNetworkAccount?: any;
  setPublicationLoader?: any;
  campaign?: ICampaigns;
  programmerPost: boolean;
}

export const ProgrammedPostMessage: React.FC<PostDetailProps> = ({
  provider,
  campaign
}) => {
  const { t } = useTranslation(['post_card, dates']);
  const { currentUser } = useSelector(selectUser);
  const userId = currentUser.user.id;
  const campaignParticipations: any = campaign?.participations;

  const participationAmbassador = campaignParticipations.find(
    (participations: any) => participations?.ambassadorId === userId
  );
  let datesTime = '';

  const participationsNetworks = participationAmbassador?.networks;
  if (participationsNetworks) {
    const participationProgrammedDate =
      participationsNetworks[provider]?.programmedDate;

    const dateFormat = new Date(participationProgrammedDate);
    const monthAbbr = t('dates:month_abbreviation', { returnObjects: true });
    const minutes = dateFormat.getMinutes();
    const formattedMinutes = String(minutes).padStart(2, '0');

    datesTime = `${
      monthAbbr[dateFormat.getMonth()]
    } ${dateFormat.getDate()} - ${dateFormat.getHours()}:${formattedMinutes}`;
  }

  return (
    <PostDetailWrapper>
      <p className="small-paragraph">
        {t('post_card:congratulations')}
        <br></br>
        {t('post_card:programmed_post_message')}
      </p>
      <p className="small-paragraph">{datesTime}</p>
      <p className="small-paragraph">
        {t('post_card:thank_you_for_participation')}
      </p>
    </PostDetailWrapper>
  );
};

export const AcceptedPostMessage: React.FC = () => {
  const { t } = useTranslation(['post_card']);
  return (
    <PostDetailWrapper>
      <p className="small-paragraph">
        {t('congratulations')}
        <br></br>
        {t('accepted_post_message')}
      </p>
      <p className="small-paragraph">{t('thank_you_for_participation')}</p>
    </PostDetailWrapper>
  );
};

export const DeletedPostMessage: React.FC = () => {
  const { t } = useTranslation(['post_card']);

  return (
    <PostDetailWrapper>
      <p className="small-paragraph">{t('deleted_post_message')}</p>
    </PostDetailWrapper>
  );
};

export const PublishedPostMessage: React.FC<PostDetailProps> = ({
  realPostUrl,
  provider,
  campaign
}) => {
  const { t } = useTranslation(['post_card']);
  return (
    <PostDetailWrapper>
      <p className="small-paragraph">
        {t('congratulations')}
        <br></br>
        {t('published_post_message')}
        <strong>{t('published')}</strong>
        {t('successfully')}
      </p>

      <p className="small-paragraph">{t('thank_you_for_participation')}</p>

      {realPostUrl != undefined && realPostUrl != '' && provider != 'tiktok' ? (
        <Button
          value={t('see_post')}
          variant="success"
          as="a"
          size="medium"
          href={realPostUrl}
          target="_blank"
        />
      ) : (
        <></>
      )}
      {provider == 'tiktok' &&
        campaign?.participations.map((participation, index) => (
          <Button
            key={index}
            value={t('see_post')}
            variant="success"
            as="a"
            size="medium"
            href={participation.networks.tiktok.urlPost}
            target="_blank"
          />
        ))}
    </PostDetailWrapper>
  );
};

export const PendingPostMessage: React.FC<PostDetailProps> = props => {
  const {
    originalContent,
    originalContent2,
    originalContent3,
    provider,
    id,
    setShowButton,
    getRankingData,
    setPublicationLoader,
    campaign,
    programmerPost
  } = props;
  const { t } = useTranslation(['content_editor']);
  const { company } = useSelector(selectCompany);
  const companyDisabledContent =
    company?.company_permission?.companyId == 178 ? false : true;
  switch (provider) {
    case 'facebook':
      return (
        <FacebookShareCard
          postId={id}
          originalContent={originalContent}
          provider={provider}
          setShowButton={setShowButton}
          getRankingData={getRankingData}
          setPublicationLoader={setPublicationLoader}
        />
      );

    case 'twitter':
      return (
        <ContentEditor
          isTwitter={true}
          provider={provider}
          postId={id}
          maxCharacters={280}
          label={t('twitter_instructions')}
          originalContent={originalContent}
          originalContent2={originalContent2}
          originalContent3={originalContent3}
          setShowButton={setShowButton}
          getRankingData={getRankingData}
          setPublicationLoader={setPublicationLoader}
          disabled={!companyDisabledContent}
          campaign={campaign}
          programmerPost={programmerPost}
          contents={campaign?.contentPerSocialNetwork.twitter}
        />
      );

    case 'tiktok':
      return !company?.company_permission?.allowTest ? (
        <ContentEditor
          isTwitter={false}
          provider={provider}
          postId={id}
          maxCharacters={150}
          label={t('tiktok_instructions')}
          originalContent={originalContent}
          originalContent2={originalContent2}
          originalContent3={originalContent3}
          setShowButton={setShowButton}
          getRankingData={getRankingData}
          setPublicationLoader={setPublicationLoader}
          disabled={!companyDisabledContent}
          campaign={campaign}
          programmerPost={programmerPost}
          contents={campaign?.contentPerSocialNetwork.tiktok}
        />
      ) : (
        <ContentTiktok
          campaign={campaign}
          postId={id}
          setPublicationLoader={setPublicationLoader}
          setShowButton={setShowButton}
          getRankingData={getRankingData}
        />
      );

    case 'linkedin':
      return (
        <ContentEditor
          isTwitter={false}
          provider={provider}
          postId={id}
          maxCharacters={1500}
          label={t('linkedin_instructions')}
          originalContent={originalContent}
          originalContent2={originalContent2}
          originalContent3={originalContent3}
          setShowButton={setShowButton}
          getRankingData={getRankingData}
          setPublicationLoader={setPublicationLoader}
          disabled={!companyDisabledContent}
          campaign={campaign}
          programmerPost={programmerPost}
          contents={campaign?.contentPerSocialNetwork.linkedin}
        />
      );

    default:
      return null;
  }
};

const PostDetail: React.FC<PostDetailProps> = props => {
  const {
    status,
    provider,
    networkFlagParticipation,
    socialNetworkAccount,
    campaignStatus,
    campaign
  } = props;
  const { t } = useTranslation([
    'campaign_card',
    'post_card',
    'networks_list',
    'campaign_section'
  ]);
  const [originalContent, setOriginalContent] = useState(
    props.originalContent || ''
  );
  const [originalContent2, setOriginalContent2] = useState(
    props.originalContent2 || ''
  );
  const [originalContent3, setOriginalContent3] = useState(
    props.originalContent3 || ''
  );
  const [showButton, setShowButton] = useState(status);
  const [programmerPost, setProgrammerPost] = useState(false);
  const [publicationLoader, setPublicationLoader] = useState(false);
  const { company } = useSelector(selectCompany);
  const { currentUser } = useSelector(selectUser);
  const dispatch = useDispatch();
  const postData = async (
    network: string,
    token: string,
    secret: string,
    verifier: string,
    screenName: string
  ) => {
    await postNetworkToken(
      currentUser?.token,
      {
        token: token,
        followers: 1,
        photo: '',
        uid: 1,
        secret: secret,
        verifier: verifier,
        screenName: screenName
      },
      network
    )
      .then(response => {
        dispatch(setUserInfoLoading(true));
        if (response.data) {
          getInfoUser(currentUser?.user?.id, currentUser?.token).then(
            response => {
              dispatch(setUserInfo(response.data));
              dispatch(setUserInfoLoading(false));
            }
          );
        }
      })
      .catch(error => {
        dispatch(setUserInfoLoading(false));
      });
  };

  const conect = (conect: string) => {
    switch (conect) {
      case 'facebook':
        window.location.href = urlFacebook(
          company?.facebookCredentials.apiKey,
          conect
        );
        break;
      case 'twitter':
        TwitterToken(currentUser?.token)
          .then(response => (window.location.href = response.data.data))
          .catch(error => console.log(error));
        break;
      case 'instagram':
        Swal.fire({
          color: Colors.secondaryDarkBlue,
          width: 400,
          imageUrl: InstagramLogo,
          imageWidth: 200,
          imageHeight: 100,
          input: 'text',
          showCloseButton: true,
          inputLabel: t('social_network:UserName'),
          confirmButtonColor: Colors.secondaryPurple
        }).then(result => {
          if (result.value != '') {
            if (result.value != undefined) {
              postData(conect, 'code', '', '', result.value);
            }
          }
        });
        break;
      case 'linkedin':
        window.location.href = urlLinkedin(
          company?.linkedinCredentials.apiKey,
          conect
        );
        break;
      case 'tiktok':
        TiktokToken(currentUser?.token)
          .then(response => {
            localStorage.setItem('idCampaignConnect', String(campaign!.id));
            window.location.href = response.data.data;
          })
          .catch(error => console.log(error));

        break;
    }
  };

  const showPostMessage = async () => {
    try {
      setPublicationLoader(true);
      if (provider != 'facebook' && provider != 'tiktok') {
        const { data: data1 } = await updateContentUrl(
          currentUser?.token,
          Number(campaign?.id),
          provider,
          originalContent
        );
        if (data1) {
          setOriginalContent(data1.data.content);
        }

        if (originalContent2) {
          const { data: data2 } = await updateContentUrl(
            currentUser?.token,
            Number(campaign?.id),
            provider,
            originalContent2
          );
          if (data2) {
            setOriginalContent2(data2.data.content);
          }
        }

        if (originalContent3) {
          const { data: data3 } = await updateContentUrl(
            currentUser?.token,
            Number(campaign?.id),
            provider,
            originalContent3
          );
          if (data3) {
            setOriginalContent3(data3.data.content);
          }
        }
      }
      setProgrammerPost(false);
      setShowButton('pending');
    } catch (error) {
      console.error('Error al actualizar el contenido:', error);
    } finally {
      setPublicationLoader(false);
    }
  };

  const showProgrammedProvider = async (providerButton: any) => {
    // setProgrammerPost(true);
    // setShowButton('pending');
    try {
      setPublicationLoader(true);

      const { data: data1 } = await updateContentUrl(
        currentUser?.token,
        Number(campaign?.id),
        provider,
        originalContent
      );
      if (data1) {
        setOriginalContent(data1.data.content);
      }

      if (originalContent2) {
        const { data: data2 } = await updateContentUrl(
          currentUser?.token,
          Number(campaign?.id),
          provider,
          originalContent2
        );
        if (data2) {
          setOriginalContent2(data2.data.content);
        }
      }

      if (originalContent3) {
        const { data: data3 } = await updateContentUrl(
          currentUser?.token,
          Number(campaign?.id),
          provider,
          originalContent3
        );
        if (data3) {
          setOriginalContent3(data3.data.content);
        }
      }

      setProgrammerPost(true);
      setShowButton('pending');
    } catch (error) {
      console.error('Error al actualizar el contenido:', error);
    } finally {
      setPublicationLoader(false);
    }
  };

  if (!socialNetworkAccount.linked) {
    return (
      <Button
        value={t('post_card:connect')}
        variant="tertiary"
        as="a"
        size="big"
        onClick={() => conect(provider)}
        target="_blank"
      />
    );
  } else {
    if (socialNetworkAccount.invalidToken) {
      dispatch(setInvalidTokenCurrent(provider));
      const invalidToken = provider;
      return (
        <Fragment>
          <p>{`${t('social_network:token_permissions', {
            invalidToken
          })}`}</p>
          <Button
            value={t('networks_list:update')}
            variant="tertiary"
            as="a"
            size="big"
            onClick={() => conect(provider)}
            target="_blank"
          />
        </Fragment>
      );
    }
    if (!networkFlagParticipation) {
      if (publicationLoader) {
        return <Spinner description={t('post_card:loading_content')} />;
      } else if (showButton === 'pending') {
        return (
          <Suspense fallback={<ThreeDots />}>
            <PendingPostMessage
              {...props}
              originalContent={originalContent}
              originalContent2={originalContent2}
              originalContent3={originalContent3}
              setShowButton={setShowButton}
              setPublicationLoader={setPublicationLoader}
              campaign={campaign}
              programmerPost={programmerPost}
            />
          </Suspense>
        );
      } else if (showButton === 'programmed') {
        return <ProgrammedPostMessage {...props} />;
      } else if (showButton === 'accepted') {
        return <AcceptedPostMessage />;
      } else if (showButton === 'published') {
        return <PublishedPostMessage {...props} />;
      } else {
        let result;
        switch (campaignStatus) {
          case 'activated':
            result = (
              <ButtonsParticipations>
                <Button
                  value={t('campaign_card:participate')}
                  variant="tertiary"
                  as="a"
                  size="big"
                  onClick={showPostMessage}
                  target="_blank"
                />

                {provider == 'facebook' ||
                  provider == 'instagram' ||
                  provider == 'whatsapp' || (
                    <Button
                      className="programmerButton"
                      value={t('campaign_card:programmed')}
                      variant="tertiary"
                      as="a"
                      size="big"
                      onClick={() => {
                        showProgrammedProvider(provider);
                      }}
                      target="_blank"
                    />
                  )}
              </ButtonsParticipations>
            );
            break;
          case 'completed':
            result = <p>{t('post_card:completed_post')}</p>;
            break;
          case 'paused':
            result = <p>{t('post_card:paused_post')}</p>;
            break;
          case 'pending':
            result = <p>{t('campaign_section:coming_soon')}</p>;
            break;

          default:
            result = (
              <ButtonsParticipations>
                <Button
                  value={t('campaign_card:participate')}
                  variant="tertiary"
                  as="a"
                  size="big"
                  onClick={showPostMessage}
                  target="_blank"
                />
                {provider == 'twitter' && (
                  <Button
                    className="programmerButton"
                    value={t('campaign_card:programmed')}
                    variant="tertiary"
                    as="a"
                    size="big"
                    onClick={() => {
                      showProgrammedProvider(provider);
                    }}
                    target="_blank"
                  />
                )}
              </ButtonsParticipations>
            );
            break;
        }
        return result;
      }
    } else {
      if (showButton === 'programmed') {
        return <ProgrammedPostMessage {...props} />;
      }
      if (showButton === 'published') {
        return <PublishedPostMessage {...props} />;
      }
      if (showButton === 'accepted') {
        return <AcceptedPostMessage />;
      }
      if (showButton === 'deleted') {
        return <DeletedPostMessage />;
      } else {
        return null;
      }
    }
  }
};

export default PostDetail;
