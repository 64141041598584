import React, { Suspense, useEffect, useState } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { getCompanyMetrics } from 'services/rest/CompanyMetrics/CompanyMetrics';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import {
  selectCompanyMetrics,
  setLoading,
  setMetrics
} from 'redux/slices/CompanyMetrics/CompanyMetricsSlice';
import SingleDataBox from 'components/boxes/SingleDataBox';
import MetricBox from 'components/boxes/MetricBox';
import { MetricsWrapper, ToggleMetricsWrapper } from './styled';
import {
  StyledTooltipWrapper,
  TooltipMessage
} from 'components/TooltipWrapper/StyledTooltipWrapper';
import MetricImgBox from 'components/boxes/MetricBoxImg';
import iconActivated from 'images/icons/bonus_approved.svg';
import iconCompleted from 'images/icons/completed.svg';
import iconHeart from 'images/icons/heart.svg';
import iconMoney from 'images/icons/money.svg';
import { nFormatter } from 'helpers/formatHelper';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import NetworkParticipationBox from 'components/boxes/NetworkParticipationBox';
import { Button } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const MetricsCampaignsSectionAdmin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['campaign_section']);
  const { currentUser } = useSelector(selectUser);
  const { token } = currentUser;
  const { loading, companyMetrics } = useSelector(selectCompanyMetrics);
  const { company } = useSelector(selectCompany);
  const [expandMetrics, setExpandMetrics] = useState(false);
  const [visible, setVisible] = useState({
    totalValorization: false,
    potential_reach: false,
    estimated_reach: false,
    publications_number: false,
    interactions_number: false
  });

  const handleClickToggle = () => setExpandMetrics(prev => !prev);

  const getData = async () => {
    const data = await getCompanyMetrics({
      token,
      typeFilter: 'campaigns'
    });
    dispatch(setMetrics(data));
    dispatch(setLoading(false));
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getData();
  }, []);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const changeTooltip = (tooltipType: string, state: boolean) => {
    const tooltipTypes = {
      ...visible,
      [tooltipType]: state
    };
    setVisible(tooltipTypes);
  };

  const networksBoxItems = [
    {
      linked: true,
      network: 'facebook',
      percentage: companyMetrics?.percentageParticipationSN?.facebook
    },
    {
      linked: true,
      network: 'twitter',
      percentage: companyMetrics?.percentageParticipationSN?.twitter
    },
    {
      linked: true,
      network: 'linkedin',
      percentage: companyMetrics?.percentageParticipationSN?.linkedin
    },
    {
      linked: true,
      network: 'instagram',
      percentage: companyMetrics?.percentageParticipationSN?.instagram
    },
    {
      linked: true,
      network: 'tiktok',
      percentage: companyMetrics?.percentageParticipationSN?.tiktok
    },
    {
      linked: true,
      network: 'whatsapp',
      percentage: companyMetrics?.percentageParticipationSN?.whatsapp
    }
  ];

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <MetricsWrapper>
        <StyledTooltipWrapper visible={false}>
          <SingleDataBox>
            <MetricBox
              iconName={'report'}
              value={`${companyMetrics?.activeCampaigns} / ${companyMetrics?.totalCampaigns}`}
              text={t('campaign_section:active_total_campaigns')}
            />
          </SingleDataBox>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper
          visible={visible?.publications_number}
          onMouseOver={() => changeTooltip('publications_number', true)}
          onMouseOut={() => changeTooltip('publications_number', false)}
        >
          <SingleDataBox>
            <MetricImgBox
              iconName={iconCompleted}
              value={companyMetrics?.totalContentsCampaigns}
              text={t('campaign_section:publications_number')}
            />
          </SingleDataBox>
          <TooltipMessage>
            {t('campaign_section:publications_number_tooltip')}
          </TooltipMessage>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper visible={false}>
          <SingleDataBox>
            <MetricImgBox
              iconName={iconActivated}
              value={companyMetrics?.ambassadorsParticipating}
              text={t('campaign_section:ambassadors_participating')}
            />
          </SingleDataBox>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper
          visible={visible.totalValorization}
          onMouseOver={() => changeTooltip('totalValorization', true)}
          onMouseOut={() => changeTooltip('totalValorization', false)}
        >
          <SingleDataBox>
            <MetricImgBox
              iconName={iconMoney}
              value={`${company?.currency?.sign}${nFormatter(
                companyMetrics?.totalValorizationReal
              )}`}
              money={company?.currency?.code}
              text={t('campaign_section:valorization')}
            />
            <TooltipMessage>
              {t('campaign_section:valorization_tooltip')}
            </TooltipMessage>
          </SingleDataBox>
        </StyledTooltipWrapper>
      </MetricsWrapper>

      {expandMetrics && (
        <MetricsWrapper>
          <StyledTooltipWrapper
            visible={visible?.interactions_number}
            onMouseOver={() => changeTooltip('interactions_number', true)}
            onMouseOut={() => changeTooltip('interactions_number', false)}
          >
            <SingleDataBox>
              <MetricImgBox
                iconName={iconCompleted}
                value={companyMetrics?.totalEngagement}
                text={t('campaign_section:interactions_number')}
              />
            </SingleDataBox>
            <TooltipMessage>
              {t('campaign_section:interactions_number_tooltip')}
            </TooltipMessage>
          </StyledTooltipWrapper>

          <StyledTooltipWrapper
            visible={visible.potential_reach}
            onMouseOver={() => changeTooltip('potential_reach', true)}
            onMouseOut={() => changeTooltip('potential_reach', false)}
          >
            <SingleDataBox>
              <MetricBox
                iconName="total-reach"
                value={`${nFormatter(companyMetrics?.totalPotentialReach)}`}
                text={t('campaign_section:potential_reach')}
              />
              <TooltipMessage>
                {t('campaign_section:potential_reach_tooltip')}
              </TooltipMessage>
            </SingleDataBox>
          </StyledTooltipWrapper>

          <StyledTooltipWrapper
            visible={visible.estimated_reach}
            onMouseOver={() => changeTooltip('estimated_reach', true)}
            onMouseOut={() => changeTooltip('estimated_reach', false)}
          >
            <SingleDataBox>
              <MetricImgBox
                iconName={iconHeart}
                value={`${nFormatter(companyMetrics?.totalEstimatedReach)}`}
                text={t('campaign_section:estimated_reach')}
              />
              <TooltipMessage>
                {t('campaign_section:estimated_reach_tooltip')}
              </TooltipMessage>
            </SingleDataBox>
          </StyledTooltipWrapper>

          <StyledTooltipWrapper visible={false}>
            <SingleDataBox>
              <NetworkParticipationBox
                onConnect={() => alert('Maybe some other time')}
                items={networksBoxItems}
              />
            </SingleDataBox>
          </StyledTooltipWrapper>
        </MetricsWrapper>
      )}

      <ToggleMetricsWrapper>
        <Button
          onClick={handleClickToggle}
          endIcon={expandMetrics ? <ExpandLess /> : <ExpandMore />}
        >
          {expandMetrics
            ? t('campaign_section:see_less')
            : t('campaign_section:see_more')}
        </Button>
      </ToggleMetricsWrapper>
    </Suspense>
  );
};

export default MetricsCampaignsSectionAdmin;
