import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import { FilterCampaigns, StyleTable } from './Styled';
import Input from 'components/inputs/Input';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { getCompanyLogs } from 'services/rest/Company/GetCompanyData';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

interface CompanyLogsProps {
  page: string;
  limit: string;
  rows: Array<{
    id: number;
    accountId: number;
    role: string;
    action: string;
    accountName: string;
    accountEmail: string;
    createdAt: string;
  }>;
}

const CompanyLogs = () => {
  const { t } = useTranslation(['logs']);
  const { currentUser } = useSelector(selectUser);
  const [logs, setLogs] = useState<CompanyLogsProps>();
  const { token } = currentUser;
  const { company } = useSelector(selectCompany);
  const timezone = company?.timezone;

  const roleOptions = [
    { label: t('logs:all_roles'), value: 'ALL' },
    { label: 'Administrator', value: 'Administrator' },
    { label: 'Ambassador', value: 'Ambassador' }
  ];

  const [filterText, setFilterText] = useState('');
  const [selectedRole, setSelectedRole] = useState(roleOptions[0]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const companyLogs = async (page: number, limit: number) => {
    const data = await getCompanyLogs(token, page, limit);
    if (data) {
      setLogs(data);
    }
  };

  const logsData = logs?.rows || [];

  const filteredLogs = logsData
    .filter(
      log =>
        log.accountName?.toLowerCase().includes(filterText.toLowerCase()) ||
        log.id?.toString().includes(filterText) ||
        log.accountEmail?.toLowerCase().includes(filterText.toLowerCase()) ||
        log.role?.toLowerCase().includes(filterText.toLowerCase()) ||
        log.action?.toLowerCase().includes(filterText.toLowerCase())
    )
    .filter(
      log => selectedRole.value === 'ALL' || log.role === selectedRole.value
    );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const selectRoleOption = (event: any) => {
    setSelectedRole(event);
    setPage(0);
  };

  useEffect(() => {
    companyLogs(1, 100);
  }, []);

  const formatDate = (isoDateString: string): string => {
    const date = new Date(isoDateString);
    return new Intl.DateTimeFormat('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: timezone
    }).format(date);
  };

  return (
    <>
      <h1>{t('logs:filter_logs')}</h1>
      <StyleTable>
        <FilterCampaigns>
          <Input
            type="search"
            placeholder={t('logs:filter_logs')}
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
          />
          <span>{t('logs:role')}</span>
          <DropdownSelect
            options={roleOptions}
            value={selectedRole}
            onSelect={selectRoleOption}
          />
        </FilterCampaigns>
        <TableContainer component={Paper} className="table_container">
          <Table
            sx={{ minWidth: 750 }}
            size="medium"
            aria-label="a dense table"
            className="table_center"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center" className="column">
                  {t('logs:id_log')}
                </TableCell>
                <TableCell align="center" className="column">
                  {t('logs:role')}
                </TableCell>
                <TableCell align="center" className="column">
                  {t('logs:account_name')}
                </TableCell>
                <TableCell align="center" className="column">
                  {t('logs:account_email')}
                </TableCell>
                <TableCell align="center" className="column">
                  {t('logs:action')}
                </TableCell>
                <TableCell align="center" className="column">
                  {t('logs:created_at')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table_body">
              {(rowsPerPage > 0
                ? filteredLogs.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredLogs
              ).map((log, index) => (
                <TableRow
                  key={log.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center">{log.id}</TableCell>
                  <TableCell align="center">{log.role}</TableCell>
                  <TableCell align="center">{log.accountName}</TableCell>
                  <TableCell align="center">{log.accountEmail}</TableCell>
                  <TableCell align="center">{log.action}</TableCell>
                  <TableCell align="center">
                    {formatDate(log.createdAt)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={filteredLogs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('logs:rows_per_page')}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} of ${count}`
          }
        />
      </StyleTable>
    </>
  );
};

export default CompanyLogs;
