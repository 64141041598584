import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Facebook as LoadingState } from 'react-content-loader';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import TwitterText from 'twitter-text';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers';
import { selectUser } from 'redux/slices/userSlice';
import {
  Igroup,
  IGroupAmssador
} from 'redux/slices/GroupAmbassador/GroupAmbassador.interface';
import {
  selectGroupAmbassador,
  setData,
  setLoadingGroupAmbassador
} from 'redux/slices/GroupAmbassador/GroupAmbassadorSlice';
import {
  CampaignData,
  createCampaign
} from 'services/rest/Campaign/createCampaign';
import { getGroupAmbassador } from 'services/rest/GetGroupAmbassador/GetGroupAmbassador';
import { RequiredString } from 'helpers/yupHelper';
import { sortGroups } from 'helpers/groupsHelper';
import { awsUploadVideoHelper } from 'helpers/awsVideoHelper';
import {
  awsUploadFileHelper,
  setFileNameHelper,
  setFilePdfHelper
} from 'helpers/awsCampaignFileHelper';

import { generateRandAlphaNumStr } from 'Utils/StringRandom';
import { facebookURLs } from 'Utils/FacebookURLs';
import { getUrlS3 } from 'constants/setup.constants';
import { ErrorAlert, SuccessAlert } from 'components/Alert';
import CreateCampaignForm from './CreateCampaignForm';

const NewCampaignPage: React.FC = () => {
  const { t } = useTranslation(['campaigns_page', 'validations']);
  const dispatch = useDispatch();
  const userSchema = yup.object().shape({
    name: RequiredString().max(
      150,
      t('validations:length_error', { max: 150 })
    ),
    description: RequiredString().max(
      1800,
      t('validations:length_error', { max: 1800 })
    )
  });

  const { register, control, handleSubmit, errors } = useForm({
    resolver: yupResolver(userSchema)
  });

  useEffect(() => {
    dispatch(setLoadingGroupAmbassador(true));
    getGroup('');
  }, []);
  const [backendError, setBackendError] = useState<string | null>(null);
  const [fileErrorInstagram, setFileErrorInstagram] = useState<string>('');
  const [errorSocialNetwork, setErrorSocialNetwork] = useState<string>('');
  const [fileErrorTwitter, setFileErrorTwitter] = useState<string>('');
  const [fileErrorLinkedin, setFileErrorLinkedin] = useState<string>('');
  const [fileErrorWhatsapp, setFileErrorWhatsapp] = useState<string>('');
  const [fileErrorTiktok, setFileErrorTiktok] = useState<string>('');
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [hourt, setHourt] = useState<any>('00');
  const [minutes, setMinutes] = useState<any>('00');

  const [image, setImage] = useState<Array<any>>([]);
  const [imageTwitter, setImageTwitter] = useState<Array<any>>([]);
  const [imageLinkedin, setImageLinkedin] = useState<Array<any>>([]);
  const [imageInstagram, setImageInstagram] = useState<Array<any>>([]);
  const [imageWhatsapp, setImageWhatsapp] = useState<Array<any>>([]);
  const [imageTiktok, setImageTiktok] = useState<Array<any>>([]);
  const [checkBox, setCheckBox] = useState(false);
  const [group, setGroup] = useState<Array<number>>([]);
  const [ambassadors, setAmbassadors] = useState<Array<number>>([]);
  const [backendSuccess, setBackendSuccess] = useState<string | null>(null);
  const [type, setType] = useState({
    instagram: 'text',
    twitter: 'text',
    linkedin: 'text',
    whatsapp: 'text',
    tiktok: 'text'
  });
  const [loader, setLoader] = useState(false);
  const [uploadedTwitter, setUploadedTwitter] = useState<boolean | null>(null);
  const [uploadedLinkedin, setUploadedLinkedin] = useState<boolean | null>(
    null
  );
  const [uploadedInstagram, setUploadedInstagram] = useState<boolean | null>(
    null
  );
  const [uploadedTiktok, setUploadedTiktok] = useState<boolean | null>(null);
  const [uploadedWhatsapp, setUploadedWhatsapp] = useState<boolean | null>(
    null
  );
  const history = useHistory();

  const { currentUser } = useSelector(selectUser);
  const { groupsAmbassador = [], loading } = useSelector(selectGroupAmbassador);

  const contentLimits = {
    twitter: 280,
    linkedin: 1500,
    instagram: 700,
    whatsapp: 280,
    tiktok: 150
  };

  const [invalidLimit, setInvalidLimit] = useState({
    twitter: false,
    linkedin: false,
    instagram: false,
    whatsapp: false,
    tiktok: false
  });

  const no_characters = /^(?!.*[!@#$%^&*()\-_+={}[\]|\\;:'"ª|!· º\\,<.>/?]{5}).+/;
  const no_mentions = /^(?:(?!\B@).\n*)+$/;

  const [linkedinUrn, setLinkedinUrn] = useState('');
  const [linkedinUrnError, setLinkedinUrnError] = useState('');

  const handleClick = (
    startDate: Date,
    finishDate: Date,
    hour: any,
    minutes: any
  ) => {
    const dateStartString = `${startDate.getFullYear()}/${
      startDate.getMonth() + 1
    }/${startDate.getDate()} ${hour}:${minutes}:00`;

    const dateStart = new Date(dateStartString);

    const dateFinishString = `${finishDate.getFullYear()}/${
      finishDate.getMonth() + 1
    }/${finishDate.getDate()} 22:59:59`;

    const dateFinish = new Date(dateFinishString);

    setStartDate(dateStart);
    setFinishDate(dateFinish);
  };

  const handleTypeFile = (body: any) => {
    setType(body);
  };

  const [socialContent, setSocialContent] = useState<{
    twitter: {
      [key: string]: string;
    };
    linkedin: {
      [key: string]: string;
    };
    tiktok: {
      [key: string]: string;
    };
  }>({
    twitter: {
      content: ''
    },
    linkedin: {
      content: ''
    },
    tiktok: {
      content: ''
    }
  });

  const handleImagen = (select: Array<any>, content: string) => {
    switch (content) {
      case 'photo':
        setImage(select);
        break;
      case 'twitter':
        setImageTwitter(select);
        break;
      case 'linkedin':
        setImageLinkedin(select);
        break;
      case 'instagram':
        setImageInstagram(select);
        break;
      case 'whatsapp':
        setImageWhatsapp(select);
        break;
      case 'tiktok':
        setImageTiktok(select);
        break;
    }
  };

  const handleParticipation = (
    group: Array<Igroup>,
    ambassadors: Array<Igroup>
  ) => {
    const groupsIds = group.map(select => select.id);
    const ambassadorsIds = ambassadors.map(select => select.id);

    setGroup(groupsIds);
    setAmbassadors(ambassadorsIds);
  };

  const getGroup = async (name: string) => {
    await getGroupAmbassador(currentUser?.token, 1, 500, name)
      .then(response => {
        const group = sortGroups(response.data.data.groups.docs);
        const ambassadors = sortGroups(response.data.data.ambassadors.docs);
        const groupAmbassador: IGroupAmssador = {
          groups: group,
          ambassador: ambassadors
        };
        dispatch(setData(groupAmbassador));
        dispatch(setLoadingGroupAmbassador(false));
      })
      .catch(error => {
        dispatch(setLoadingGroupAmbassador(false));
      });
  };

  const regextValidationTwitter = (content: string) => {
    const regex = new RegExp(no_characters);
    return regex.test(content);
  };

  const regextValidationTiktok = (content: string) => {
    const regex = new RegExp(no_mentions);
    return regex.test(content);
  };

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const onSubmit = async (campaignData: CampaignData) => {
    setLoader(true);
    if (checkBox) {
      if (finishDate.getMonth() - startDate.getMonth() != 1) {
        setBackendError(t('date_range_month_error'));
        setLoader(false);
        return;
      }
    } else {
      if (finishDate.getMonth() != startDate.getMonth()) {
        setBackendError(t('date_range_error'));
        setLoader(false);
        return;
      }
    }

    if (image.length === 0) {
      setBackendError(t('campaigns_page:image_required'));
      setLoader(false);
      return;
    }

    if (ambassadors.length == 0 && group.length == 0) {
      setBackendError(t('error_info_ambassador'));
      setLoader(false);
      return;
    }

    if (
      !campaignData.includesFacebook &&
      !campaignData.includesTwitter &&
      !campaignData.includesLinkedin &&
      !campaignData.includesInstagram &&
      !campaignData.includesWhatsapp &&
      !campaignData.includesTiktok
    ) {
      setErrorSocialNetwork(t('error_social_network'));
      setBackendError(t('error_social_network'));
      setLoader(false);
      return;
    }

    const pointsValue: any = {
      facebook: { ...campaignData?.facebook },
      twitter: { ...campaignData?.twitter },
      linkedin: { ...campaignData?.linkedin },
      instagram: { ...campaignData?.instagram },
      whatsapp: { ...campaignData?.whatsapp },
      tiktok: { ...campaignData?.tiktok }
    };
    for (const network in pointsValue) {
      const isEmptyPoint = Object.values(pointsValue[network]).some(
        point => point === null || point === ''
      );
      if (isEmptyPoint) {
        setBackendError(t('error_sn_points'));
        setLoader(false);
        return;
      }
    }

    if (
      campaignData.includesFacebook &&
      !facebookURLs(campaignData.contentFacebook)
    ) {
      setBackendError(t('error_url'));
      setLoader(false);
      return;
    }

    if (campaignData.includesTwitter) {
      for (const key of Object.keys(socialContent.twitter)) {
        const validationContent = regextValidationTwitter(
          socialContent.twitter[key]
        );
        const contentTwitterLength = socialContent.twitter[key]
          ? TwitterText.getTweetLength(socialContent.twitter[key])
          : 0;
        if (type.twitter === 'text' && !socialContent.twitter[key]) {
          setBackendError(t('error_text_twitter'));
          setLoader(false);
          return;
        } else if (!validationContent) {
          setBackendError(t('characters'));
          setLoader(false);
          return;
        } else if (contentTwitterLength > contentLimits.twitter) {
          setBackendError(t('error_limit'));
          setInvalidLimit({
            ...invalidLimit,
            twitter: true
          });
          setLoader(false);
          return;
        }
      }
      campaignData.twitterContents = socialContent.twitter;
    }

    if (
      (type.twitter === 'image' &&
        campaignData.includesTwitter &&
        imageTwitter.length == 0) ||
      (type.twitter === 'video' &&
        campaignData.includesTwitter &&
        imageTwitter.length == 0)
    ) {
      setBackendError(t('error_info_twitter'));
      setFileErrorTwitter(t('error_info_tw_text'));
      setLoader(false);
      return;
    }

    if (campaignData.includesLinkedin) {
      for (const key of Object.keys(socialContent.linkedin)) {
        const urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
        const countPattern = (
          (socialContent.linkedin[key] || '').match(urlPattern) || []
        ).length;
        const contentLinkedinLength = socialContent.linkedin[key]
          ? socialContent.linkedin[key]
              .replace(/(?:https?|ftp):\/\/\S+/g, ' ')
              .trim().length + countPattern
          : 0;
        if (
          (type.linkedin === 'text' || type.linkedin === 'share') &&
          !socialContent.linkedin[key]
        ) {
          setBackendError(t('error_text_linkedin'));
          setLoader(false);
          return;
        } else if (contentLinkedinLength > contentLimits.linkedin) {
          setBackendError(t('error_limit'));
          setInvalidLimit({
            ...invalidLimit,
            linkedin: true
          });
          setLoader(false);
          return;
        }
      }
      if (type.linkedin != 'share') {
        campaignData.linkedinContents = socialContent.linkedin;
      } else {
        if (type.linkedin === 'share' && !campaignData.contentLinkedinShare) {
          setBackendError(t('error_text_linkedin'));
          setLoader(false);
          return;
        } else if (type.linkedin === 'share' && !linkedinUrn) {
          setLinkedinUrnError(t('error_urn_linkedin'));
          setLoader(false);
          return;
        }
        campaignData.linkedinContents = campaignData.contentLinkedinShare;
      }
    }

    if (
      (type.linkedin === 'image' &&
        campaignData.includesLinkedin &&
        imageLinkedin.length == 0) ||
      (type.linkedin === 'video' &&
        campaignData.includesLinkedin &&
        imageLinkedin.length == 0) ||
      (type.linkedin === 'pdf' &&
        campaignData.includesLinkedin &&
        imageLinkedin.length == 0)
    ) {
      setBackendError(t('error_info_linkedin'));
      setFileErrorLinkedin(t('error_info_file_ln'));
      setLoader(false);
      return;
    }

    if (campaignData.includesInstagram) {
      const urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
      const countPattern = (
        (campaignData.contentInstagram || '').match(urlPattern) || []
      ).length;
      const contentInstagramLength = campaignData.contentInstagram
        ? campaignData.contentInstagram
            .replace(/(?:https?|ftp):\/\/\S+/g, ' ')
            .trim().length + countPattern
        : 0;

      if (
        !campaignData.contentInstagram ||
        campaignData.contentInstagram.trim().length === 0
      ) {
        setBackendError(t('error_text_instagram'));
        setLoader(false);
        return;
      }

      if (imageInstagram.length == 0) {
        setBackendError(t('error_info_instagram'));
        setFileErrorInstagram(t('error_info_instagram'));
        setLoader(false);
        return;
      } else if (contentInstagramLength > contentLimits.instagram) {
        setBackendError(t('error_limit'));
        setInvalidLimit({
          ...invalidLimit,
          instagram: true
        });
        setLoader(false);
        return;
      }
    }

    if (campaignData.includesWhatsapp) {
      const urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
      const countPattern = (
        (campaignData.contentWhatsapp || '').match(urlPattern) || []
      ).length;
      const contentWhatsappLength = campaignData.contentWhatsapp
        ? campaignData.contentWhatsapp
            .replace(/(?:https?|ftp):\/\/\S+/g, ' ')
            .trim().length + countPattern
        : 0;

      if (
        !campaignData.contentWhatsapp ||
        campaignData.contentWhatsapp.trim().length === 0
      ) {
        setBackendError(t('error_text_whatsapp'));
        setLoader(false);
        return;
      }

      if (imageWhatsapp.length == 0) {
        setBackendError(t('error_info_whatsapp'));
        setFileErrorWhatsapp(t('error_info_wa_text'));
        setLoader(false);
        return;
      } else if (contentWhatsappLength > contentLimits.whatsapp) {
        setBackendError(t('error_limit'));
        setInvalidLimit({
          ...invalidLimit,
          whatsapp: true
        });
        setLoader(false);
        return;
      }
    }

    if (campaignData.includesTiktok) {
      for (const key of Object.keys(socialContent.tiktok)) {
        const validationContent = regextValidationTiktok(
          socialContent.tiktok[key]
        );
        const urlPattern = /((https?:\/\/)?(www\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,}([/\w .-]*)?)/g;

        const countPattern = (
          (socialContent.tiktok[key] || '').match(urlPattern) || []
        ).length;
        const contentTiktokLength = socialContent.tiktok[key]
          ? socialContent.tiktok[key]
              .replace(/(?:https?|ftp):\/\/\S+/g, ' ')
              .trim().length + countPattern
          : 0;

        if (!socialContent.tiktok[key]) {
          setBackendError(t('error_text_tiktok'));
          setLoader(false);
          return;
        } else if (socialContent.tiktok[key].match(urlPattern)) {
          setBackendError(t('error_no_urls'));
          setLoader(false);
          return;
        } else if (imageTiktok.length == 0) {
          setBackendError(t('error_info_tiktok'));
          setFileErrorTiktok(t('error_info_tiktok'));
          setLoader(false);
          return;
        } else if (!validationContent) {
          setBackendError(t('pattern_tiktok'));
          setLoader(false);
          return;
        } else if (contentTiktokLength > contentLimits.tiktok) {
          setBackendError(t('error_limit'));
          setInvalidLimit({
            ...invalidLimit,
            tiktok: true
          });
          setLoader(false);
          return;
        }
      }
      campaignData.tiktokContents = socialContent.tiktok;
    }

    if (
      type.tiktok === 'video' &&
      campaignData.includesTiktok &&
      imageTiktok.length == 0
    ) {
      setBackendError(t('error_info_tiktok'));
      setFileErrorTiktok(t('error_info_file_tk'));
      setLoader(false);
      return;
    }

    campaignData.startDate = new Date(
      `${startDate.getFullYear()}/${
        startDate.getMonth() + 1
      }/${startDate.getDate()} ${hourt}:${minutes}`
    ).toString();
    campaignData.finishDate = new Date(
      `${finishDate.getFullYear()}/${
        finishDate.getMonth() + 1
      }/${finishDate.getDate()} 22:59:59`
    ).toString();
    campaignData.companyId = currentUser.user.companyId;
    campaignData.ambassadorsIds = ambassadors;
    campaignData.groupsIds = group;

    if (image) {
      const namePhoto = generateRandAlphaNumStr();
      const uploadedPhoto = await awsUploadFileHelper(
        currentUser?.token,
        image,
        true,
        'CAMPAIGN',
        namePhoto,
        t,
        setBackendError
      );
      if (uploadedPhoto) {
        campaignData.photo = getUrlS3('campaigns', namePhoto);
      } else {
        setBackendError(t('validations:file_error'));
        setLoader(false);
        return;
      }
    }

    let twitter: any;
    if (campaignData.includesTwitter) {
      if (type.twitter === 'image') {
        const twitterFile = [];
        if (imageTwitter) {
          setUploadedTwitter(false);
        }
        for (const file of imageTwitter) {
          if (!file?.file?.type) {
            const name = file.url.split('/').reverse()[0];
            twitterFile.push({ name });
            continue;
          }
          const nameTwitter = setFileNameHelper(file, false);
          await awsUploadFileHelper(
            currentUser?.token,
            file,
            false,
            'CAMPAIGN',
            nameTwitter,
            t,
            setBackendError
          );
          twitterFile.push({ name: nameTwitter });
        }
        twitter = {
          type: type['twitter'],
          url: twitterFile.map((file: any) => getUrlS3('campaigns', file?.name))
        };
      }
      if (type.twitter === 'video') {
        setUploadedTwitter(false);
        const nameTwitter = setFileNameHelper(imageTwitter, false);
        const uploadedTwitter = await awsUploadVideoHelper(
          currentUser?.token,
          imageTwitter,
          'CAMPAIGN',
          nameTwitter,
          t,
          setBackendError
        );
        if (uploadedTwitter) {
          twitter = {
            type: type['twitter'],
            url: getUrlS3('campaigns', nameTwitter)
          };
          setUploadedTwitter(true);
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
    }

    let linkedin: any;
    if (campaignData.includesLinkedin) {
      if (type.linkedin === 'image') {
        const linkedinFile = [];
        if (imageLinkedin) {
          setUploadedLinkedin(false);
        }
        for (const file of imageLinkedin) {
          if (!file?.file?.type) {
            const name = file.url.split('/').reverse()[0];
            linkedinFile.push({ name });
            continue;
          }
          const nameLinkedin = setFileNameHelper(file, false);
          await awsUploadFileHelper(
            currentUser?.token,
            file,
            false,
            'CAMPAIGN',
            nameLinkedin,
            t,
            setBackendError
          );
          linkedinFile.push({ name: nameLinkedin });
        }
        linkedin = {
          type: type['linkedin'],
          url: linkedinFile.map((file: any) =>
            getUrlS3('campaigns', file?.name)
          )
        };
      }
      if (type.linkedin === 'video') {
        setUploadedLinkedin(false);
        const nameLinkedin = setFileNameHelper(imageLinkedin, false);
        const uploadedLinkedin = await awsUploadVideoHelper(
          currentUser?.token,
          imageLinkedin,
          'CAMPAIGN',
          nameLinkedin,
          t,
          setBackendError
        );
        if (uploadedLinkedin) {
          linkedin = {
            type: type['linkedin'],
            url: getUrlS3('campaigns', nameLinkedin)
          };
          setUploadedLinkedin(true);
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
      if (type.linkedin === 'pdf') {
        const nameLinkedin = setFilePdfHelper(imageLinkedin[0].name);
        const uploadedLinkedin = await awsUploadFileHelper(
          currentUser?.token,
          imageLinkedin[0],
          false,
          'CAMPAIGN',
          `${currentUser.user.companyId}/${nameLinkedin}`,
          t,
          setBackendError
        );
        if (uploadedLinkedin) {
          linkedin = {
            type: type['linkedin'],
            url: getUrlS3(
              `campaigns/${currentUser.user.companyId}`,
              nameLinkedin
            )
          };
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
      if (type.linkedin === 'share') {
        if (linkedinUrn) {
          linkedin = {
            type: type['linkedin'],
            url: linkedinUrn,
            contentParticipation: socialContent.linkedin.content
          };
        } else {
          setLinkedinUrnError(t('error_urn_linkedin'));
          setLoader(false);
          return;
        }
      }
    }

    let instagram: any;
    if (campaignData.includesInstagram) {
      if (type.instagram === 'image') {
        const nameInstagram = setFileNameHelper(imageInstagram[0], false);
        const uploadedInstagramPhoto = await awsUploadFileHelper(
          currentUser?.token,
          imageInstagram[0],
          false,
          'CAMPAIGN',
          nameInstagram,
          t,
          setBackendError
        );
        if (uploadedInstagramPhoto) {
          instagram = {
            type: type['instagram'],
            url: [getUrlS3('campaigns', nameInstagram)]
          };
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
      if (type.instagram === 'video') {
        setUploadedInstagram(false);
        const nameInstagram = setFileNameHelper(imageInstagram, false);
        const uploadedInstagram = await awsUploadVideoHelper(
          currentUser?.token,
          imageInstagram,
          'CAMPAIGN',
          nameInstagram,
          t,
          setBackendError
        );
        if (uploadedInstagram) {
          instagram = {
            type: type['instagram'],
            url: getUrlS3('campaigns', nameInstagram)
          };
          setUploadedInstagram(true);
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
    }

    let whatsapp: any;
    if (campaignData.includesWhatsapp) {
      if (type.whatsapp === 'image') {
        const nameWhatsapp = setFileNameHelper(imageWhatsapp[0], false);
        const uploadedWhatsappPhoto = await awsUploadFileHelper(
          currentUser?.token,
          imageWhatsapp[0],
          false,
          'CAMPAIGN',
          nameWhatsapp,
          t,
          setBackendError
        );
        if (uploadedWhatsappPhoto) {
          whatsapp = {
            type: type['whatsapp'],
            url: [getUrlS3('campaigns', nameWhatsapp)]
          };
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }

      if (type.whatsapp === 'video') {
        setUploadedWhatsapp(false);
        const nameWhatsapp = setFileNameHelper(imageWhatsapp, false);
        const uploadedWhatsapp = await awsUploadVideoHelper(
          currentUser?.token,
          imageWhatsapp,
          'CAMPAIGN',
          nameWhatsapp,
          t,
          setBackendError
        );
        if (uploadedWhatsapp) {
          whatsapp = {
            type: type['whatsapp'],
            url: getUrlS3('campaigns', nameWhatsapp)
          };
          setUploadedWhatsapp(true);
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
    }

    let tiktok: any;
    if (campaignData.includesTiktok) {
      if (type.tiktok === 'video') {
        setUploadedTiktok(false);
        const nameTiktok = setFileNameHelper(imageTiktok, false);
        const uploadedTiktok = await awsUploadVideoHelper(
          currentUser?.token,
          imageTiktok,
          'CAMPAIGN',
          nameTiktok,
          t,
          setBackendError
        );
        if (uploadedTiktok) {
          tiktok = {
            type: type['tiktok'],
            url: getUrlS3('campaigns', nameTiktok)
          };
          setUploadedInstagram(true);
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
    }

    const { data, errors } = await createCampaign(
      currentUser?.token,
      campaignData,
      twitter,
      linkedin,
      instagram,
      tiktok,
      whatsapp,
      type
    );

    if (data) {
      history.push('/admin/campaigns', t('success_message'));
    }

    if (errors) {
      setBackendError(errors.data);
      setLoader(false);
      return;
    }

    setLoader(false);
  };

  return (
    <>
      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}

      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}

      <CreateCampaignForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        handleClick={handleClick}
        groupsAmbassador={groupsAmbassador}
        getData={getGroup}
        handleParticipation={handleParticipation}
        handleImage={handleImagen}
        control={control}
        handleTypeFile={handleTypeFile}
        errorSocialNetwork={errorSocialNetwork}
        fileErrorInstagram={fileErrorInstagram}
        fileErrorTwitter={fileErrorTwitter}
        fileErrorLinkedin={fileErrorLinkedin}
        fileErrorWhatsapp={fileErrorWhatsapp}
        fileErrorTiktok={fileErrorTiktok}
        loader={loader}
        setHourt={setHourt}
        setMinutes={setMinutes}
        sHour={hourt}
        sMinutes={minutes}
        contentLimits={contentLimits}
        invalidLimit={invalidLimit}
        uploadedTwitter={uploadedTwitter}
        uploadedLinkedin={uploadedLinkedin}
        uploadedInstagram={uploadedInstagram}
        uploadedTiktok={uploadedTiktok}
        uploadedWhatsapp={uploadedWhatsapp}
        linkedinUrn={linkedinUrn}
        setLinkedinUrn={setLinkedinUrn}
        linkedinUrnError={linkedinUrnError}
        setLinkedinUrnError={setLinkedinUrnError}
        checkBox={checkBox}
        setCheckBox={setCheckBox}
        socialContent={socialContent}
        setSocialContent={setSocialContent}
      />
    </>
  );
};

export default NewCampaignPage;
