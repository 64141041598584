import React, { useEffect, useState, Suspense } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import Button from 'components/buttons/Button';
import NoDataCard from 'components/NoDataCard';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import CampaignsAdminCard from './CampaignsAdminCard';
import AllCampaignsStatus from './CampaignsAdminCard/AllCampaignsStatus';
import { SuccessAlert } from 'components/Alert';

import iconEditOutline from 'images/icons/edit-outline.svg';
import EmptyCampaigns from 'images/logos/empty_campaigns.png';

import { ColorButton, MarginAdminPage, FilterSection } from './styled';
import NavigationLink from 'components/links/NavigationLink';

import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { getCompanyCampaigns } from 'services/rest/Campaign/getCompanyCampaigns';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import Input from 'components/inputs/Input';
import MetricsCampaignsSectionAdmin from './MetricsCampaignsSectionAdmin';

const CampaignsAdminPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { state } = location as any;
  const { t } = useTranslation([
    'admin_dashboard',
    'campaign_section',
    'campaign_card',
    'campaigns_page'
  ]);
  const { currentUser } = useSelector(selectUser);
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [backendSuccess, setBackendSuccess] = useState<string | null>(state);
  const [filterText, setFilterText] = useState('');
  const statusOptions = [
    { label: t('campaign_card:all'), value: 'ALL' },
    { label: t('campaign_card:paused'), value: 'paused' },
    { label: t('campaign_card:actived'), value: 'activated' },
    { label: t('campaign_card:pending'), value: 'pending' },
    { label: t('campaign_card:completed'), value: 'completed' }
  ];
  const [selectedStatus, setStatus] = useState(statusOptions[0]);
  const [campaignByStatus, setCampaignByStatus] = useState([]);

  const getData = async () => {
    const { data } = await getCompanyCampaigns(currentUser?.token, 500, 1);
    setCampaigns(data.data.docs);
    showAlertSuccessCampaignCreated();
    setLoading(false);
  };
  const showAlertSuccessCampaignCreated = () => {
    const queryParams = new URLSearchParams(location.search);
    const campaignCreated = queryParams.get('backend_success_campaign_created');
    if (campaignCreated != null && campaignCreated == 'true') {
      setBackendSuccess(t('campaign_section:created_campaign'));
    }
  };

  const selectOption = (event: any) => {
    let filter = [] as any;
    if (event.value !== 'ALL') {
      filter = campaigns.filter(
        (campaign: any) => campaign.status === event.value
      );
    }
    setCampaignByStatus(filter);
    setStatus(event);
    return filter;
  };

  const new_campaigns = campaigns.filter(campaign => {
    const data = campaign as any;
    const result =
      data.name.toLowerCase().indexOf(filterText.toLowerCase()) != -1;
    return result;
  });

  const filterList =
    selectedStatus.value !== 'ALL'
      ? new_campaigns.filter(
          (campaign: any) => campaign.status === selectedStatus.value
        )
      : new_campaigns;

  const currentCampaigns = filterText ? filterList : campaignByStatus;

  const handleFilterText = (event: any) => {
    setFilterText(event.target.value);
  };

  useEffect(() => {
    getData();
  }, []);

  const activatedCampaigns = campaigns.filter(
    (campaign: any) => campaign.status === 'activated'
  );

  const pendingCampaigns = campaigns.filter(
    (campaign: any) => campaign.status === 'pending'
  );

  const pausedCampaigns = campaigns.filter(
    (campaign: any) => campaign.status === 'paused'
  );

  const completedCampaigns = campaigns.filter(
    (campaign: any) => campaign.status === 'completed'
  );

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <>
      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}
      <Suspense fallback={<LoadingState height="230px" width="100%" />}>
        <SectionWrapper>
          <MarginAdminPage>
            <p>{t('campaign_section:active_campaigns')}</p>
            {campaigns?.length > 0 && (
              <Button
                className="hide-on-mobile"
                value={t('admin_dashboard:create_campaign')}
                variant="primary"
                onClick={() =>
                  history.replace({ pathname: `/campaigns/admin/new` })
                }
              />
            )}
          </MarginAdminPage>
          <ColorButton>
            <Button
              className="show-on-mobile"
              value={t('admin_dashboard:create_campaign')}
              variant="primary"
              onClick={() =>
                history.replace({ pathname: `/campaigns/admin/new` })
              }
            />
          </ColorButton>

          <MetricsCampaignsSectionAdmin />

          <FilterSection>
            <Input
              type="search"
              placeholder={t('campaign_card:search_campaign')}
              value={filterText}
              onChange={handleFilterText}
            />
            <span>{t('campaign_card:filter')}</span>
            <DropdownSelect
              options={statusOptions}
              value={selectedStatus}
              onSelect={selectOption}
            />
          </FilterSection>
        </SectionWrapper>
        {currentCampaigns?.length > 0 && (
          <SectionWrapper>
            {currentCampaigns.map((campaign: any, index: any) => {
              return (
                <CampaignsAdminCard
                  key={index}
                  id={campaign.id}
                  url={campaign.photo}
                  startDate={campaign.startDate}
                  finishDate={campaign.finishDate}
                  description={campaign.name}
                  active={campaign.ambassadorsParticipating}
                  publicacion={campaign.totalParticipation}
                  iconEdit={iconEditOutline}
                  status={campaign.status}
                  includesFacebook={campaign.includesFacebook}
                  includesTwitter={campaign.includesTwitter}
                  includesLinkedin={campaign.includesLinkedin}
                  includesInstagram={campaign.includesInstagram}
                  includesWhatsapp={campaign.includesWhatsapp}
                  includesTiktok={campaign.includesTiktok}
                  includesStatus={false}
                />
              );
            })}
            <NavigationLink
              text={t('admin_dashboard:see_campaigns')}
              direction={'forward'}
              to={'/campaigns/admin/campaignsAll'}
            />
          </SectionWrapper>
        )}
        {selectedStatus.value === 'ALL' && filterText === '' && (
          <>
            {pausedCampaigns?.length > 0 && (
              <AllCampaignsStatus
                campaigns={pausedCampaigns}
                title={t('campaigns_page:paused')}
              />
            )}
            {activatedCampaigns?.length > 0 && (
              <AllCampaignsStatus
                campaigns={activatedCampaigns}
                title={t('campaigns_page:active')}
              />
            )}
            {pendingCampaigns?.length > 0 && (
              <AllCampaignsStatus
                campaigns={pendingCampaigns}
                title={t('campaigns_page:pending')}
              />
            )}
            {completedCampaigns?.length > 0 && (
              <AllCampaignsStatus
                campaigns={completedCampaigns}
                title={t('campaigns_page:completed')}
              />
            )}
          </>
        )}
        {campaigns?.length === 0 && currentCampaigns?.length === 0 && (
          <SectionWrapper>
            <NoDataCard
              title={t('campaign_section:create_new_campaign')}
              text={t('campaign_section:time_to_start')}
              img={EmptyCampaigns}
              button={[
                {
                  text: t('campaign_section:create_campaign'),
                  action: '/campaigns/admin/new'
                }
              ]}
            />
          </SectionWrapper>
        )}
      </Suspense>
    </>
  );
};

export default CampaignsAdminPage;
