import styled from 'styled-components';
import BaseBox from 'components/boxes/BaseBox';
import { StyledNetworkParticipationBox } from 'components/boxes/NetworkParticipationBox';
import { Breakpoints, Colors } from 'styles/Constants';

export const Heard = styled.div`
  display: flex;
  margin-bottom: 30px;

  h2 {
    font-size: 20px;
    color: ${Colors.secondaryNormalGrey};
    font-weight: bold;
  }

  button {
    margin-left: 69%;
    font-size: 1.125rem;
    height: 20%;
    @media (max-width: ${Breakpoints.xxmedium}) {
      margin-left: 55%;
      height: 5%;
    }
  }
`;

export const HeardMobile = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const GridWrapper = styled.div`
  ${BaseBox};

  &.group-card {
    cursor: pointer;
    grid-template-columns: repeat(12, 1fr);
    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    > div:first-child {
      grid-column: span 2;
      h2 {
        width: 100%;
      }
    }
    > div:nth-of-type(n + 2) {
      grid-column: span 3;
    }
    > div:last-child {
      grid-column: span 1;
    }
    .division-right {
      position: relative;
    }
    .division-right:before {
      content: '';
      background-color: #d8d8d8;
      position: absolute;
      border-radius: 8px;
      height: 100%;
      width: 4px;
      right: 0px;
    }
  }

  img {
    cursor: pointer;
    width: 20px;
    margin-top: 10px;
    margin-left: 40px;
    @media (max-width: ${Breakpoints.xxmedium}) {
      margin-left: 0px;
    }
  }

  padding: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);

  h2 {
    font-weight: bold;
    position: relative;
    font-size: 18px;
    margin-left: 20px;
    width: 100px;
    @media (max-width: ${Breakpoints.xmedium}) {
      margin-left: 5px;
      margin-right: -45px;
    }
  }
`;

export const GridGroups = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(6, 1fr);
`;

export const MobileWrapper = styled.div`
  ${BaseBox};
  display: grid;
  padding: 15px;

  ${StyledNetworkParticipationBox} {
    padding-bottom: 15px;

    .button-collection {
      justify-content: flex-start;
    }

    h3 {
      text-align: center;
      width: 100%;
    }
  }
`;

export const MarginDivMobile = styled.div`
  display: flex;

  h2 {
    justify-self: flex-start;
    font-weight: bold;
    position: relative;
    font-size: 18px;
    width: 100%;
    color: ${Colors.secondaryNormalGrey};
  }
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  img {
    width: 30px;
    height: 30px;
    margin-top: 0px;
  }

  h4 {
    font-weight: bold;
    min-height: 30px;
    font-size: 14px;
    margin: 0 0 15px 0;
    min-height: auto;
    width: 94%;
    color: ${Colors.secondaryNormalGrey};
  }

  p {
    margin-top: 3px;
    color: ${Colors.primaryDarkBlue};
    font-weight: 700;
  }
`;

export const MarginDiv = styled.div`
  font-size: 0.6875rem;
  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
  }
  p {
    margin-top: 8px;
    margin-bottom: 0px;
    text-align: center;
    color: #004fea;
    font-size: 3.125rem;
    font-weight: 700;
    line-height: 1;
  }

  h4 {
    font-weight: bold;
    margin-bottom: 8px;
    min-height: 30px;
    font-size: 14px;
    width: 200px;
    text-align: center;
    @media (max-width: ${Breakpoints.xxmedium}) {
      width: 130px;
    }
  }
`;

export const Colum = styled.div`
  background-color: #d8d8d8;
  border-radius: 8px;
  margin: 0 20px;
  height: 50%;
  width: 4px;

  @media (max-width: ${Breakpoints.medium}) {
    background-color: #ffff;
    margin: 0 5px;
  }
`;

//Form create

export const StyledForm = styled.div`
  display: flex;

  label {
    color: #4d4d4d;
    font-size: 1rem;
    font-weight: 600;
    margin: 10px;
    margin-left: 20%;
    margin-right: 5%;
  }

  .cutText {
    margin-left: 18%;
    margin-right: 5%;
    margin-bottom: auto;
    width: 180px;
    padding: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  input {
    width: 40%;
  }

  div.dropdown-filter-content__control {
    width: 160px;
    margin-top: 20px;
    border: 1px solid #979797;
    border-radius: 30px;
    background-color: transparent;
    box-shadow: none;
    height: 30px;

    &:hover,
    &:focus-within {
      border-color: ${Colors.primaryDarkBlue};
      cursor: pointer;
    }
  }

  div.styledFilter {
    width: 170px;
    border: none;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid #a5adba;
    box-shadow: none;
    height: 30px;
  }
`;

export const TextForm = styled.div`
  p {
    font-size: 12px;
    line-height: 1.42857;
    color: #333333;
    background-color: #ff;
    margin: 10px;
    margin-left: 20%;
    margin-right: 5%;
  }
`;

export const MarginUpload = styled.div`
  margin-left: 40%;
  margin-bottom: 10%;
`;

export const MarginButton = styled.div`
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  button {
    text-align: center;
    margin: auto;
    display: flex;
    @media (max-width: ${Breakpoints.small}) {
      margin-top: 20px;
    }
  }
`;

export const MetricsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-top: 20px;

  @media (min-width: ${Breakpoints.medium}) {
    grid-column-gap: 20px;
  }

  > div:nth-of-type(-n + 3) {
    grid-column: span 4;
    margin-bottom: 20px;
  }

  .single-data-box {
    display: grid;
    margin-bottom: 0px;
    height: 100%;
  }
`;

export const ToggleMetricsWrapper = styled.div`
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-end;

  button {
    color: ${Colors.primaryDarkBlue};
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.42857;
  }
`;
