export const network = (
  facebook: boolean,
  twitter: boolean,
  linkedin: boolean,
  instagram: boolean,
  tiktok: boolean,
  whatsapp: boolean
): Array<string> => {
  return [
    facebook ? 'facebook' : '',
    twitter ? 'twitter' : '',
    linkedin ? 'linkedin' : '',
    instagram ? 'instagram' : '',
    tiktok ? 'tiktok' : '',
    whatsapp ? 'whatsapp' : ''
  ];
};

export const networkUser = (
  linkedFacebook: boolean,
  linkedTwitter: boolean,
  linkedLinkedin: boolean,
  linkedInstagram: boolean,
  linkedTiktok: boolean,
  facebookInvalidToken: boolean,
  twitterInvalidToken: boolean,
  linkedinInvalidToken: boolean,
  instagramInvalidToken: boolean,
  tiktokInvalidToken: boolean,
  usernameFacebook: boolean,
  usernameTwitter: boolean,
  usernameLinkedin: boolean,
  usernameInstagram: boolean,
  usernameTiktok: boolean
): Array<any> => {
  const data = [
    {
      id: 1,
      linked: linkedFacebook,
      invalidToken: facebookInvalidToken,
      username: usernameFacebook,
      network: 'facebook'
    },
    {
      id: 2,
      linked: linkedTwitter,
      invalidToken: twitterInvalidToken,
      username: usernameTwitter,
      network: 'twitter'
    },
    {
      id: 3,
      linked: linkedLinkedin,
      invalidToken: linkedinInvalidToken,
      username: usernameLinkedin,
      network: 'linkedin'
    },
    {
      id: 4,
      linked: linkedInstagram,
      invalidToken: instagramInvalidToken,
      username: usernameInstagram,
      network: 'instagram'
    },
    {
      id: 5,
      linked: linkedTiktok,
      invalidToken: tiktokInvalidToken,
      username: usernameTiktok,
      network: 'tiktok'
    }
  ];

  return data;
};
