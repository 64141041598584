import React, { Suspense, useEffect, useState } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';

import SingleDataBox from 'components/boxes/SingleDataBox';
import MetricBox from 'components/boxes/MetricBox';
import MetricImgBox from 'components/boxes/MetricBoxImg';

import iconMoney from 'images/icons/money.svg';
import iconHeart from 'images/icons/heart.svg';
import iconGraph from 'images/icons/graph.svg';

import { GridWrapper, MobileAdminWrapper } from './Styled';

import { getCompanyMetrics } from 'services/rest/CompanyMetrics/CompanyMetrics';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import {
  selectCompanyMetrics,
  setLoading,
  setMetrics
} from 'redux/slices/CompanyMetrics/CompanyMetricsSlice';
import { nFormatter } from 'helpers/formatHelper';
import {
  StyledTooltipWrapper,
  TooltipMessage
} from 'components/TooltipWrapper/StyledTooltipWrapper';
import moment from 'moment';
import PerformanceFilters, {
  PlainFilters
} from './PerformanceFilters/PerformanceFilters';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

export default function AdminPerformanceSection() {
  const dispatch = useDispatch();
  const { company, loading: companyLoading } = useSelector(selectCompany);
  const { t } = useTranslation(['admin_dashboard']);
  const { currentUser } = useSelector(selectUser);
  const { token } = currentUser;
  const { loading, companyMetrics } = useSelector(selectCompanyMetrics);

  const monthsData = t('dates:month', {
    returnObjects: true,
    defaultValue: []
  });

  const months = Array.isArray(monthsData)
    ? monthsData.map((month, index) => ({
        label: month,
        value: index + 1
      }))
    : [];

  const quartersData = t('dates:quarter', {
    returnObjects: true,
    defaultValue: []
  });

  const quarters = Array.isArray(quartersData)
    ? quartersData.map((quarter, index) => ({
        label: quarter,
        value: index + 1
      }))
    : [];

  const years = [
    moment().year() as any,
    (moment().year() - 1) as any,
    (moment().year() - 2) as any,
    (moment().year() - 3) as any,
    (moment().year() - 4) as any,
    (moment().year() - 5) as any
  ].map(label => ({ label, value: label }));

  const periodTypeOptions = [
    { label: 'TODOS', value: 'all' },
    { label: t('ranking_filters:month'), value: 'month' },
    { label: t('ranking_filters:quarter'), value: 'quarter' },
    { label: t('ranking_filters:year'), value: 'year' }
  ];

  const [year, setYear] = useState<any>(years[0]);
  const [periodType, setPeriodType] = useState(periodTypeOptions[0]);
  const currentMonth = moment().format('M') as any;
  const [periodValue, setPeriodValue] = useState<any>(months[currentMonth - 1]);

  const [filters, setFilters] = useState<PlainFilters | null>({
    periodType: periodType && periodType.value,
    periodValue: periodValue && periodValue.value,
    year: year.value
  });

  const [visible, setVisible] = useState({
    accumulatedValuation: false,
    engagementTotal: false,
    potentialReach: false,
    estimatedScope: false,
    totalContent: false
  });

  const getData = async (filters: any) => {
    let data: any = null;
    switch (filters == null ? 'all' : filters.periodType) {
      case 'month':
        data = await getCompanyMetrics({
          token,
          typeFilter: `${filters?.periodType}`,
          year: `${filters?.year}`,
          month: `${filters?.periodValue}`
        });
        break;
      case 'quarter':
        data = await getCompanyMetrics({
          token,
          typeFilter: `${filters?.periodType}`,
          year: `${filters?.year}`,
          quarter: `${filters?.periodValue}`
        });
        break;
      case 'year':
        data = await getCompanyMetrics({
          token,
          typeFilter: `${filters?.periodType}`,
          year: `${filters?.year}`
        });
        break;

      default:
        data = await getCompanyMetrics({
          token,
          typeFilter: `all`
        });
        break;
    }

    dispatch(setMetrics(data));
    dispatch(setLoading(false));
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getData(filters);
  }, []);

  if (loading || companyLoading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const changeTooltip = (tooltipType: string, state: boolean) => {
    const tooltipTypes = {
      ...visible,
      [tooltipType]: state
    };
    setVisible(tooltipTypes);
  };

  const onChangeFilters = async (filters: any) => {
    setFilters(filters);
    await getData(filters);
  };

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <div className="section-head">
        <h1 className="section-title">{t('employee_dashboard:data_report')}</h1>
        <PerformanceFilters
          periodType={periodType}
          setPeriodType={setPeriodType}
          periodTypeOptions={periodTypeOptions}
          periodValue={periodValue}
          setPeriodValue={setPeriodValue}
          months={months}
          quarters={quarters}
          years={years}
          currentMonth={currentMonth}
          year={year}
          setYear={setYear}
          onChangeFilters={onChangeFilters}
        />
      </div>
      <GridWrapper className="hide-on-mobile isAdmin">
        <StyledTooltipWrapper
          visible={visible?.accumulatedValuation}
          onMouseOver={() => changeTooltip('accumulatedValuation', true)}
          onMouseOut={() => changeTooltip('accumulatedValuation', false)}
        >
          <SingleDataBox>
            <MetricImgBox
              iconName={iconMoney}
              value={`${company?.currency?.sign} ${nFormatter(
                companyMetrics?.acumulateValuation
              )}`}
              money={company?.currency?.code}
              text={t('admin_dashboard:accumulated_valuation')}
            />
          </SingleDataBox>
          <TooltipMessage>
            {t('admin_dashboard:accumulated_valuation_tooltip')}
          </TooltipMessage>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper
          visible={visible?.engagementTotal}
          onMouseOver={() => changeTooltip('engagementTotal', true)}
          onMouseOut={() => changeTooltip('engagementTotal', false)}
        >
          <SingleDataBox>
            <MetricImgBox
              iconName={iconHeart}
              value={nFormatter(companyMetrics?.totalEngagement)}
              text={t('admin_dashboard:total_engagement')}
            />
            <TooltipMessage>
              {t('admin_dashboard:total_engagement_tooltip')}
            </TooltipMessage>
          </SingleDataBox>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper
          visible={visible?.totalContent}
          onMouseOver={() => changeTooltip('totalContent', true)}
          onMouseOut={() => changeTooltip('totalContent', false)}
        >
          <SingleDataBox>
            <MetricBox
              iconName="report"
              value={nFormatter(companyMetrics?.totalContent)}
              text={t('admin_dashboard:total_contents')}
            />
            <TooltipMessage>
              {t('admin_dashboard:total_content_tooltip')}
            </TooltipMessage>
          </SingleDataBox>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper
          visible={visible?.potentialReach}
          onMouseOver={() => changeTooltip('potentialReach', true)}
          onMouseOut={() => changeTooltip('potentialReach', false)}
        >
          <SingleDataBox>
            <MetricBox
              iconName="total-reach"
              value={nFormatter(companyMetrics?.totalPotentialReach)}
              text={t('admin_dashboard:potential_reach')}
            />
            <TooltipMessage>
              {t('admin_dashboard:potential_reach_tooltip')}
            </TooltipMessage>
          </SingleDataBox>
        </StyledTooltipWrapper>
        <StyledTooltipWrapper
          visible={visible?.estimatedScope}
          onMouseOver={() => changeTooltip('estimatedScope', true)}
          onMouseOut={() => changeTooltip('estimatedScope', false)}
        >
          <SingleDataBox>
            <MetricImgBox
              iconName={iconGraph}
              value={nFormatter(companyMetrics?.totalEstimatedReach)}
              text={t('admin_dashboard:estimated_reach')}
            />
            <TooltipMessage>
              {t('admin_dashboard:estimated_reach_tooltip')}
            </TooltipMessage>
          </SingleDataBox>
        </StyledTooltipWrapper>
      </GridWrapper>
      <MobileAdminWrapper className="show-on-mobile">
        <StyledTooltipWrapper
          visible={visible?.accumulatedValuation}
          onMouseOver={() => changeTooltip('accumulatedValuation', true)}
          onMouseOut={() => changeTooltip('accumulatedValuation', false)}
        >
          <SingleDataBox>
            <MetricImgBox
              iconName={iconMoney}
              value={nFormatter(companyMetrics?.acumulateValuation)}
              text={t('admin_dashboard:accumulated_valuation')}
            />
            <TooltipMessage>
              {t('admin_dashboard:accumulated_valuation_tooltip')}
            </TooltipMessage>
          </SingleDataBox>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper visible={false}>
          <SingleDataBox>
            <MetricBox
              iconName="report"
              value={nFormatter(companyMetrics?.totalCampaigns)}
              text={t('admin_dashboard:total_contents')}
            />
          </SingleDataBox>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper
          visible={visible?.estimatedScope}
          onMouseOver={() => changeTooltip('estimatedScope', true)}
          onMouseOut={() => changeTooltip('estimatedScope', false)}
        >
          <SingleDataBox>
            <MetricImgBox
              iconName={iconGraph}
              value={nFormatter(companyMetrics?.totalEstimatedReach)}
              text={t('admin_dashboard:estimated_reach')}
            />
            <TooltipMessage>
              {t('admin_dashboard:estimated_reach_tooltip')}
            </TooltipMessage>
          </SingleDataBox>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper
          visible={visible?.engagementTotal}
          onMouseOver={() => changeTooltip('engagementTotal', true)}
          onMouseOut={() => changeTooltip('engagementTotal', false)}
        >
          <SingleDataBox>
            <MetricImgBox
              iconName={iconHeart}
              value={nFormatter(companyMetrics?.totalEngagement)}
              text={t('admin_dashboard:total_engagement')}
            />
            <TooltipMessage>
              {t('admin_dashboard:total_engagement_tooltip')}
            </TooltipMessage>
          </SingleDataBox>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper
          visible={visible?.potentialReach}
          onMouseOver={() => changeTooltip('potentialReach', true)}
          onMouseOut={() => changeTooltip('potentialReach', false)}
        >
          <SingleDataBox>
            <MetricBox
              iconName="total-reach"
              value={nFormatter(companyMetrics?.totalPotentialReach)}
              text={t('admin_dashboard:potential_reach')}
            />
            <TooltipMessage>
              {t('admin_dashboard:potential_reach_tooltip')}
            </TooltipMessage>
          </SingleDataBox>
        </StyledTooltipWrapper>
      </MobileAdminWrapper>
    </Suspense>
  );
}
