import Client from 'config/axios';

export const getGroupById = async (
  token: string,
  id: any,
  searchWithAmbassadorspage: string
) => {
  try {
    const { data } = await Client.get(
      `group/${id}?searchWithAmbassadors=${searchWithAmbassadorspage}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return { data };
  } catch (error) {
    return Promise.reject(error.response || error.message);
  }
};
