import React, { useEffect, useState, Suspense } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import Button from 'components/buttons/Button';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { Heard, HeardMobile } from './Styled';
import GroupAdminCard, { GroupCard } from './GroupAdminCard/GroupAdminCard';

import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { getGroupsList } from 'services/rest/Group/getGroupsList';
import { sortGroups } from 'helpers/groupsHelper';
import { SuccessAlert } from 'components/Alert';
import MetricsGroupsSectionAdmin from './MetricsGroupsSectionAdmin';

const GroupAdminPage = () => {
  const { t } = useTranslation(['group_page', 'group_section']);
  const history = useHistory();
  const location = useLocation();
  const { state } = location as any;
  const { currentUser } = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState<GroupCard[]>([]);
  const [backendSuccess, setBackendSuccess] = useState<string | null>(state);

  const getData = async () => {
    const { data } = await getGroupsList(currentUser?.token, 500, 1);
    setGroups(sortGroups(data.data.docs));
    showAlertSuccessGroupCreated();
    setLoading(false);
  };

  const showAlertSuccessGroupCreated = () => {
    const queryParams = new URLSearchParams(location.search);
    const groupCreated = queryParams.get('backend_success_group_created');
    if (groupCreated != null && groupCreated == 'true') {
      setBackendSuccess(t('group_section:created_group'));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  return (
    <>
      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}
      <Suspense fallback={<LoadingState height="230px" width="100%" />}>
        <SectionWrapper>
          <Heard>
            <h1>{t('group_page:groups')}</h1>
            <Button
              className="hide-on-mobile"
              value={t('group_page:createGroup')}
              variant="primary"
              onClick={() => history.replace({ pathname: `/admin/group/new` })}
            />
          </Heard>
          <HeardMobile>
            <Button
              className="show-on-mobile"
              value={t('group_page:createGroup')}
              variant="primary"
              onClick={() => history.replace({ pathname: `/admin/group/new` })}
            />
          </HeardMobile>

          <MetricsGroupsSectionAdmin />

          <h1>{t('group_page:created_groups')}</h1>
          {groups.map((group, index) => (
            <GroupAdminCard
              key={index}
              id={group.id}
              name={group.name}
              totalAmbassadors={group.totalAmbassadors}
              connectedAmbassadors={group.connectedAmbassadors}
              totalReach={group.totalReach}
              isAll={group.isAll}
            />
          ))}
        </SectionWrapper>
      </Suspense>
    </>
  );
};

export default GroupAdminPage;
