import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { SocialMediaIcon, IconWithStatus } from 'components/icons/Catalogue';
import Button from 'components/buttons/Button';
import { urlFacebook, urlLinkedin } from 'constants/setup.constants';
import { TwitterToken } from 'services/rest/Network/twitterToken';
import { TiktokToken } from 'services/rest/Network/getAuthToken';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import { useLocation } from 'react-router-dom';
import { setRouterCurrent } from 'redux/slices/Router/RouterSlice';
import {
  postNetworkToken,
  unlinkedNetwork
} from 'services/rest/Network/Network';
import {
  selectUser,
  setUserInfo,
  setUserInfoLoading
} from 'redux/slices/userSlice';
import { getInfoUser } from 'services/rest/logout';
import { Colors } from 'styles/Constants';
import InstagramLogo from 'images/logos/logo-instragram.png';
import { setInvalidTokenCurrent } from 'redux/slices/InvalidToken/InvalidTokenSlice';
import SocialComponent from 'components/SocialNetworkList';
import { ErrorAlert, SuccessAlert } from 'components/Alert';

export interface NetworkProps {
  linked: boolean;
  invalidToken: boolean;
  username: boolean;
  network: string;
}

export interface NetworksListProps {
  networks: Array<NetworkProps>;
}

const NetworksList = (props: NetworksListProps) => {
  const { networks } = props;
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { t } = useTranslation(['networks_list', 'social_network']);
  const dispatch = useDispatch();

  const { company } = useSelector(selectCompany);
  const { currentUser } = useSelector(selectUser);

  const { pathname } = useLocation();

  dispatch(setRouterCurrent(pathname));

  const postData = async (
    network: string,
    token: string,
    secret: string,
    verifier: string,
    screenName: string
  ) => {
    await postNetworkToken(
      currentUser?.token,
      {
        token: token,
        followers: 1,
        photo: '',
        uid: 1,
        secret: secret,
        verifier: verifier,
        screenName: screenName
      },
      network
    )
      .then(response => {
        dispatch(setUserInfoLoading(true));
        if (response.data) {
          getInfoUser(currentUser?.user?.id, currentUser?.token).then(
            response => {
              dispatch(setUserInfo(response.data));
              dispatch(setUserInfoLoading(false));
              setSuccessMessage(t('networks_list:succes_connect'));
            }
          );
        } else {
          setErrorMessage(t('networks_list:error_connect'));
        }
      })
      .catch(error => {
        dispatch(setUserInfoLoading(false));
      });
  };

  const GetActionsForNetwork = (invalidToken: boolean) => {
    const { t } = useTranslation(['networks_list']);
    const actions = [
      {
        text: t('networks_list:profile'),
        onClick: profileSocialNetwork
      },
      {
        text: t('networks_list:unlink'),
        onClick: disconnect
      }
    ];

    if (invalidToken) {
      actions.unshift({
        text: t('networks_list:update'),
        onClick: conect
      });
    }

    return actions;
  };

  const conect = (conect: string) => {
    switch (conect) {
      case 'facebook':
        window.location.href = urlFacebook(
          company?.facebookCredentials.apiKey,
          conect
        );

        break;
      case 'twitter':
        TwitterToken(currentUser?.token)
          .then(response => (window.location.href = response.data.data))
          .catch(error => console.log(error));

        break;
      case 'instagram':
        Swal.fire({
          color: Colors.secondaryDarkBlue,
          width: 400,
          imageUrl: InstagramLogo,
          imageWidth: 200,
          imageHeight: 100,
          input: 'text',
          showCloseButton: true,
          inputLabel: t('social_network:UserName'),
          confirmButtonText: t('networks_list:connect'),
          confirmButtonColor: Colors.secondaryPurple
        }).then(result => {
          if (result.value != '') {
            if (result.value != undefined) {
              postData(conect, 'code', '', '', result.value);
            }
          }
        });
        break;
      case 'linkedin':
        window.location.href = urlLinkedin(
          company?.linkedinCredentials.apiKey,
          conect
        );

        break;
      case 'tiktok':
        TiktokToken(currentUser?.token)
          .then(response => (window.location.href = response.data.data))
          .catch(error => console.log(error));

        break;
    }
  };

  const profileSocialNetwork = (network: string) => {
    let linkProfile;
    switch (network) {
      case 'facebook':
        linkProfile = `${networks[0].username}`;
        break;
      case 'twitter':
        linkProfile = `https://twitter.com/${networks[1].username}`;
        break;
      case 'linkedin':
        linkProfile = `https://www.linkedin.com/in/${networks[2].username}`;
        break;
      case 'instagram':
        linkProfile = `https://www.instagram.com/${networks[3].username}`;
        break;
      case 'tiktok':
        linkProfile = `https://www.tiktok.com/@${networks[4].username}`;
        break;
    }
    window.open(linkProfile, '_blank');
  };

  const disconnect = (network: string) => {
    unlinkedNetwork(currentUser?.token, network)
      .then(response => {
        if (response.data) {
          dispatch(setUserInfoLoading(true));
          getInfoUser(currentUser?.user?.id, currentUser?.token).then(
            response => {
              dispatch(setUserInfo(response.data));
              dispatch(setUserInfoLoading(false));
              setSuccessMessage(t('networks_list:succes_disconnect'));
            }
          );
        } else {
          setErrorMessage(t('networks_list:error_disconnect'));
        }
      })
      .catch(error => {
        dispatch(setUserInfoLoading(false));
      });
  };

  return (
    <>
      {networks.map((networkItem: any, index: any) => {
        const { linked, invalidToken, network } = networkItem;
        if (linked) {
          if (invalidToken) {
            dispatch(setInvalidTokenCurrent(network));
          }
          const status = invalidToken ? 'alert' : 'success';

          return (
            <>
              {successMessage && (
                <SuccessAlert
                  text={successMessage}
                  allowClose={true}
                  onClose={() => setSuccessMessage(null)}
                />
              )}
              {errorMessage && (
                <ErrorAlert
                  text={errorMessage}
                  allowClose={true}
                  onClose={() => setErrorMessage(null)}
                />
              )}
              <SocialComponent
                invalidToken={invalidToken}
                network={network}
                status={status}
                items={GetActionsForNetwork}
              />
            </>
          );
        } else {
          return (
            <React.Fragment key={index}>
              <Button
                className="hide-on-mobile"
                variant={network}
                size="small"
                icon={network}
                value={t('networks_list:connect')}
                onClick={() => conect(network)}
              />
              <IconWithStatus
                onClick={() => conect(network)}
                className="show-on-mobile connect-account"
                status="danger"
              >
                <SocialMediaIcon
                  size="medium"
                  variant="disabled"
                  shape="square"
                  name={network}
                />
              </IconWithStatus>
            </React.Fragment>
          );
        }
      })}
    </>
  );
};

export default NetworksList;
