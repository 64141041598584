import styled from 'styled-components';

export const FacebookAlbum = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  width: 100%;

  a {
    text-decoration: none;
  }
  img,
  p {
    margin: 0;
    padding: 0;
  }
  .fb-post {
    background-color: #ffffff;
  }
  .header {
    display: flex;
    min-height: 80px;
    padding-top: 10px;
    .img-user {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .texts {
      margin: 0 0 0 10px;
    }
    .username {
      font-size: 15px;
      color: #000000;
      font-weight: 600;
    }
    .message {
      color: #000000;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.5;
    }
  }
  .img-post {
    width: 100%;
    height: 300px;
  }
`;
