import React from 'react';
import { useTranslation } from 'react-i18next';

import { DescriptionHeader, TotalPointsCircle, TotalPoints } from './Style';
import Icon from 'components/icons/Icon';
import { useMonthAndDayRange } from 'helpers/dateHelpers';
import { ICampaigns } from 'redux/slices/Campaigns/Campaigns.interface';

export interface CampaignHeaderProps {
  campaign?: ICampaigns;
}

const CampaignHeader: React.FC<CampaignHeaderProps> = props => {
  const { campaign } = props;
  const { t } = useTranslation(['campaigns_page']);

  const startDate = campaign?.startDate
    ? campaign?.startDate
    : new Date().toString();
  const finishDate = campaign?.finishDate
    ? campaign?.finishDate
    : new Date().toString();

  const dateRange = useMonthAndDayRange(startDate, finishDate);

  return (
    <div className="grid-block">
      <DescriptionHeader className="span-sm-12 span-md-7">
        <h1>{campaign?.name}</h1>
        <span className="dates">
          <Icon size="small" name="calendar-o" />
          {dateRange}
        </span>
        <p>{campaign?.description}</p>
      </DescriptionHeader>

      <TotalPoints className="span-sm-12 span-md-5">
        <TotalPointsCircle>
          {campaign?.participations[0]
            ? campaign?.participations[0]?.totalPoints
            : '0'}
        </TotalPointsCircle>
        <h3>{t('total_points')}</h3>
      </TotalPoints>
    </div>
  );
};

export default CampaignHeader;
