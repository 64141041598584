import Client from 'config/axios';

export const getGroupsList = async (
  token: string,
  limit: number,
  page: number
) => {
  try {
    const { data } = await Client.get(`groups?limit=${limit}&page=${page}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data };
  } catch (error) {
    return Promise.reject(error.response || error.message);
  }
};
