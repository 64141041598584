import React, { Suspense, useEffect, useState } from 'react';
import { Facebook as LoadingState } from 'react-content-loader';
import { useTranslation } from 'react-i18next';

import { getCompanyMetrics } from 'services/rest/CompanyMetrics/CompanyMetrics';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import {
  selectCompanyMetrics,
  setLoading,
  setMetrics
} from 'redux/slices/CompanyMetrics/CompanyMetricsSlice';

import SingleDataBox from 'components/boxes/SingleDataBox';
import MetricBox from 'components/boxes/MetricBox';
import NetworkParticipationBox from 'components/boxes/NetworkParticipationBox';
import { MetricsWrapper, ToggleMetricsWrapper } from './Styled';
import {
  StyledTooltipWrapper,
  TooltipMessage
} from 'components/TooltipWrapper/StyledTooltipWrapper';
import MetricImgBox from 'components/boxes/MetricBoxImg';
import iconActivated from 'images/icons/bonus_approved.svg';
import iconCompleted from 'images/icons/completed.svg';
import iconHeart from 'images/icons/heart.svg';
import { nFormatter } from 'helpers/formatHelper';
import { Button } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const MetricsGroupsSectionAdmin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['group_page']);
  const { currentUser } = useSelector(selectUser);
  const { token } = currentUser;
  const { loading, companyMetrics } = useSelector(selectCompanyMetrics);
  const [expandMetrics, setExpandMetrics] = useState(false);
  const [visible, setVisible] = useState({
    connected_total_ambassadors: false,
    participating_connected_ambassadors: false,
    potential_reach: false,
    estimated_reach: false
  });

  const handleClickToggle = () => setExpandMetrics(prev => !prev);

  const getData = async () => {
    const data = await getCompanyMetrics({
      token,
      typeFilter: 'groups'
    });
    dispatch(setMetrics(data));
    dispatch(setLoading(false));
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getData();
  }, []);

  if (loading) {
    return <LoadingState height="230px" width="100%" />;
  }

  const changeTooltip = (tooltipType: string, state: boolean) => {
    const tooltipTypes = {
      ...visible,
      [tooltipType]: state
    };
    setVisible(tooltipTypes);
  };

  const networksBoxItems = [
    {
      linked: true,
      network: 'facebook',
      percentage: companyMetrics?.percentageSNAmbassador?.facebook
    },
    {
      linked: true,
      network: 'twitter',
      percentage: companyMetrics?.percentageSNAmbassador?.twitter
    },
    {
      linked: true,
      network: 'linkedin',
      percentage: companyMetrics?.percentageSNAmbassador?.linkedin
    },
    {
      linked: true,
      network: 'instagram',
      percentage: companyMetrics?.percentageSNAmbassador?.instagram
    },
    {
      linked: true,
      network: 'tiktok',
      percentage: companyMetrics?.percentageSNAmbassador?.tiktok
    }
  ];

  return (
    <Suspense fallback={<LoadingState height="230px" width="100%" />}>
      <MetricsWrapper>
        <StyledTooltipWrapper visible={false}>
          <SingleDataBox>
            <MetricBox
              iconName={'report'}
              value={companyMetrics?.totalGroups}
              text={t('group_page:created_groups')}
            />
          </SingleDataBox>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper
          visible={visible?.connected_total_ambassadors}
          onMouseOver={() => changeTooltip('connected_total_ambassadors', true)}
          onMouseOut={() => changeTooltip('connected_total_ambassadors', false)}
        >
          <SingleDataBox>
            <MetricImgBox
              iconName={iconCompleted}
              value={`${companyMetrics?.connectedAmbassadors} / ${companyMetrics?.totalAmbassadors}`}
              text={t('group_page:connected_total_ambassadors')}
            />
          </SingleDataBox>
          <TooltipMessage>
            {t('group_page:connected_total_ambassadors_tooltip')}
          </TooltipMessage>
        </StyledTooltipWrapper>

        <StyledTooltipWrapper
          visible={visible?.participating_connected_ambassadors}
          onMouseOver={() =>
            changeTooltip('participating_connected_ambassadors', true)
          }
          onMouseOut={() =>
            changeTooltip('participating_connected_ambassadors', false)
          }
        >
          <SingleDataBox>
            <MetricImgBox
              iconName={iconActivated}
              value={`${companyMetrics?.ambassadorsParticpatingPercentage}%`}
              text={t('group_page:participating_connected_ambassadors')}
            />
          </SingleDataBox>
          <TooltipMessage>
            {t('group_page:participating_connected_ambassadors_tooltip')}
          </TooltipMessage>
        </StyledTooltipWrapper>
      </MetricsWrapper>

      {expandMetrics && (
        <MetricsWrapper>
          <StyledTooltipWrapper
            visible={visible.potential_reach}
            onMouseOver={() => changeTooltip('potential_reach', true)}
            onMouseOut={() => changeTooltip('potential_reach', false)}
          >
            <SingleDataBox>
              <MetricBox
                iconName="total-reach"
                value={`${nFormatter(companyMetrics?.totalPotentialReach)}`}
                text={t('group_page:potential_reach')}
              />
              <TooltipMessage>
                {t('group_page:potential_reach_tooltip')}
              </TooltipMessage>
            </SingleDataBox>
          </StyledTooltipWrapper>

          <StyledTooltipWrapper
            visible={visible.estimated_reach}
            onMouseOver={() => changeTooltip('estimated_reach', true)}
            onMouseOut={() => changeTooltip('estimated_reach', false)}
          >
            <SingleDataBox>
              <MetricImgBox
                iconName={iconHeart}
                value={`${nFormatter(companyMetrics?.totalEstimatedReach)}`}
                text={t('group_page:estimated_reach')}
              />
              <TooltipMessage>
                {t('group_page:estimated_reach_tooltip')}
              </TooltipMessage>
            </SingleDataBox>
          </StyledTooltipWrapper>

          <StyledTooltipWrapper visible={false}>
            <SingleDataBox>
              <NetworkParticipationBox
                onConnect={() => alert('Maybe some other time')}
                items={networksBoxItems}
                msgTopText={t('group_page:connected_social_networks')}
                columns={2}
              />
            </SingleDataBox>
          </StyledTooltipWrapper>
        </MetricsWrapper>
      )}

      <ToggleMetricsWrapper>
        <Button
          onClick={handleClickToggle}
          endIcon={expandMetrics ? <ExpandLess /> : <ExpandMore />}
        >
          {expandMetrics ? t('group_page:see_less') : t('group_page:see_more')}
        </Button>
      </ToggleMetricsWrapper>
    </Suspense>
  );
};

export default MetricsGroupsSectionAdmin;
